import React, { useState, useRef } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const Section1 = () => {
  const faqData = [
    {
      question: "Can the chatbot help me track my progress towards well-being goals?",
      answer:
        "The chatbot allows you to set specific well-being goals, and it will regularly check in to monitor your progress. You can update the chatbot on your achievements, setbacks, and any changes in your goals.",
    },
    {
      question: "Can the chatbot provide real-time voice support in case of challenges or setbacks?",
      answer:
        "Yes, the chatbot is equipped to offer real-time voice support. If it detects challenges or setbacks in your well-being journey, it will engage in a voice conversation to provide immediate guidance and assistance.",
    },
    {
      question: "Can I customize the chatbot to suit my preferences?",
      answer:
        "The chatbot allows you to set specific well-being goals, and it will regularly check in to monitor your progress. You can update the chatbot on your achievements, setbacks, and any changes in your goals.",
    },
    {
      question: "Is my data secure and private?",
      answer:
        "Yes, we prioritize the security and privacy of user data. Our platform adheres to strict data protection measures and industry standards.",
    },
    {
      question: "What kind of issues does the chatbot address?",
      answer:
        "The chatbot can assist with a variety of challenges, including stress from work, school, family, relationships, anxiety, and concerns about the future or social media.",
    },
    {
      question: "How do I get started with the AI mental health coach?",
      answer:
        "Simply sign up for an account, choose between the free and premium versions, and start your personalized mental health journey. The chatbot will guide you through the setup process.",
    },
    {
      question: "How often will I receive updates on my emotional insights?",
      answer:
        "Users receive a weekly update summarizing their emotional insights based on their interactions with the chatbot.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);
  const faqContent = useRef();

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <section className="section1 faq-section">
      <header className="header-text-center">
        <h1>
          <span>Frequently Asked Questions</span>
        </h1>
      </header>

      <div className="faqs-container">
        {faqData.map((item, index) => (
          <div ref={faqContent} className="faq-box" key={index} onClick={() => toggleAccordion(index)}>
            <div className="faq-content-top">
              <h3>{item.question}</h3>
              <div className="faq-icon">{openIndex === index ? <FaChevronUp /> : <FaChevronDown />}</div>
            </div>
            <div className="faq-content-bottom">{openIndex === index && <p>{item.answer}</p>}</div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Section1;
