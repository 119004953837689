import React, { useState, useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

import { initializeApp } from "firebase/app";
import firebaseConfig from "../backend/firebaseConfig";
import * as firebaseAuth from "firebase/auth";

const app = initializeApp(firebaseConfig);
const auth = firebaseAuth.getAuth(app);

const BASE_URL = process.env.REACT_APP_BASE_URL;

const AuthService = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [UIDcookies, setUIDCookie, removeUIDCookie] = useCookies(["uid"]);
  const [isLoggedInCheck, setIsLoggedInCheck] = useState(false);
  let refreshTimer;

  const loginUser = async (credentials) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/login`, credentials, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.data.success) {
        const persistent = await firebaseAuth.setPersistence(auth, firebaseAuth.browserLocalPersistence);
        await firebaseAuth.signInWithEmailAndPassword(auth, credentials.email, credentials.password);
        const tokenExpirationTime = 55 * 60 * 1000; // 55 minutes in milliseconds
        refreshTimer = setInterval(async () => {
          await axios.post(`${BASE_URL}/api/auth/login`, credentials, {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          });
        }, tokenExpirationTime);
        setIsLoggedInCheck(true);
      }
      return response.data;
    } catch (error) {
      console.error("Error logging in:", error);
      return { success: false };
    }
  };

  const logoutUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/auth/logout`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      removeCookie("token");
      removeUIDCookie("uid");
      clearInterval(refreshTimer);
      firebaseAuth.signOut(auth);

      setIsLoggedInCheck(false);
      return true;
    } catch (error) {
      console.error("Error logging out: ", error);
      return false;
    }
  };

  const isLoggedIn = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/loggedIn`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.data;
      return response.data;
    } catch (error) {
      console.error("Error checking login status: ", error);
      return false;
    }
  };

  const getStripeSubscriptionStatus = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/stripe-status`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      return response.data;
    } catch (error) {
      console.error("Stripe subscription status error: ", error);
      throw error;
    }
  };

  const getUserDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await response.data;
      return data;
    } catch (error) {
      console.error("Error getting user details: ", error);
      return false;
    }
  };

  const isAuthenticated = () => {
    return !!cookies.token;
  };

  const registerUser = async (userData) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/`, userData, {
        withCredentials: true,
      });
      // successful register
      if (response.status === 200) {
        setIsLoggedInCheck(true);
        return { success: true, message: "User registered successfully" };
      } else {
        return { success: false, message: "Sorry, an error occurred during sign up. Please try again." };
      }
    } catch (error) {
      console.error("Error registering user: ", error);
      if (error.response.status === 400) {
        return { success: false, message: "A user with that email already exists. Please use a different email." };
      } else {
        return { success: false, message: "Sorry, an error occurred during sign up. Please try again." };
      }
    }
  };

  const googleSignup = async (userData) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/auth/googleSignup`, userData, { withCredentials: true });
      // successful register
      if (response.status === 200) {
        setIsLoggedInCheck(true);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error registering user", error);
      return false;
    }
  };

  const manageSubscriptionStripe = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/payment/manage-subscription`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      return response.data;
    } catch (error) {
      console.error("Error managing stripe subscription: ", error);
      return false;
    }
  };

  const changePasswordDashboard = async (currentPassword, newPassword, uid) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/api/user/change-password`,
        { currentPassword, newPassword, uid },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating password: ", error.message);
      throw error;
    }
  };

  const changeSignupPref = async (userData) => {
    try {
      const response = await axios.put(`${BASE_URL}/api/user/`, userData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error updating user data: ", error.message);
      throw error;
    }
  };

  const deleteUser = async () => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/user/`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error deleting user: ", error.message);
      throw error;
    }
  };

  return {
    loginUser,
    logoutUser,
    isAuthenticated,
    registerUser,
    googleSignup,
    isLoggedIn,
    getUserDetails,
    getStripeSubscriptionStatus,
    manageSubscriptionStripe,
    changePasswordDashboard,
    changeSignupPref,
    deleteUser,
  };
};

export default AuthService;
