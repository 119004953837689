import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { FaUserAlt, FaRobot, FaCopy, FaRedo, FaPaperPlane, FaChevronDown } from "react-icons/fa";
import { MdOutlineMic, MdOutlineMicOff } from "react-icons/md";
import { GiSpeaker, GiSpeakerOff } from "react-icons/gi";
import { BiSend } from "react-icons/bi";
import axios from "axios";
// import "./UploadForm.css";
import Modal from "./Modal";
import config from "./Config.json";
import aiAvatarImage from "../images/logo.png";
import ChatApi from "../Services/ChatApi";
import VoiceInterface from "./VoiceInterface";
import ChatGoalCards from "../components/ChatGoalCards";

function Chatbot(
  {
    isNightMode,
    currentChatId,
    selectedChatMessages,
    selectedChatMessagesRefresh,
    isCreatingNewChat,
    allChats,
    setAllChats,
    updateChatTitle,
    setCurrentChatId,
    currentSession,
    setCurrentSession,
    setIsCreatingNewChat,
    setSelectedSessionId,
    stripeSubscriptionStatus,
    onBasicUserPremiumFeatureClick,
    setShowChatGoalCards,
    chatGoalAndStyle,
    setChatGoalAndStyle,
    getChatGoalAndStyle,
    submitButtonUsedForNewChatSession,
    onCreateNewChatSession,
    key, // Quick Fix. Do not try to access this. Aids in correctly rendering the chats.
  },
  ref
) {
  const { createNewChat, getAllChats, getMessagesFromChat, deleteChat, generateChat, setChatTitle } = ChatApi();
  const [text, setText] = useState("");
  const [dots, setDots] = useState(1);
  const [chat, setChat] = useState([
    {
      text: "Hello there! My name is Kevin, and I am a personalized mental health chatbot trained in positive psychology. How can I help you today?",
      from: "bot",
    },
  ]);
  const [isModalOpen, toggleModalView] = useState(false);
  const [isBotTyping, setBotTyping] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [overallTimestamp, setOverallTimestamp] = useState(new Date());
  const [copyButtonRendered, setCopyButtonRendered] = useState({});
  const [chatMessages, setChatMessages] = useState({});
  const [currentMessages, setCurrentMessages] = useState([]);
  const [isCreatingNewChatState, setIsCreatingNewChatState] = useState(isCreatingNewChat);
  const [chatbotSpeechEnabled, setChatbotSpeechEnabled] = useState(true);

  const getBotResponse = async (chatID = currentChatId) => {
    const chatData = text;
    setText("");
    setBotTyping(true);
    setChat((prevChat) => [...prevChat, { text: "...", from: "bot" }]);
    for (let i = 0; i < 4; i++) {
      await new Promise((resolve) => setTimeout(resolve, 500));
      setChat((prevChat) => [...prevChat.slice(0, -1), { text: `${"....".slice(0, i + 1)}`, from: "bot" }]);
    }

    try {
      const userMessage = chatData.trim();
      const botRes = await generateChat(chatID, { text: userMessage });
      const responseWords = parseBotResponse(botRes.resonse);
      setChat((prevChat) => [...prevChat.slice(0, -1), { text: " ", from: "bot" }]);

      if (chatbotSpeechEnabled && stripeSubscriptionStatus === "Premium") {
        await textToSpeech(botRes.resonse, chatbotVoice); // Generate speech
      }

      // Display the response word by word
      for (let i = 0; i < responseWords.length; i++) {
        await new Promise((resolve) => setTimeout(resolve, 50));
        setChat((prevChat) => [
          ...prevChat.slice(0, -1),
          { text: responseWords.slice(0, i + 1).join(" "), from: "bot" },
        ]);
      }
      setBotTyping(false);
      const newMessage = botRes.resonse;
      addMessage(chatID, newMessage);
      return newMessage;
    } catch (error) {
      console.error(error);
      setBotTyping(false);
    }
  };

  const parseBotResponse = (botResponse) => {
    // First split on whitespace to get the initial array of words and whitespace
    const splitResponse = botResponse.split(/(\s+)/).filter((e) => e.length > 0);

    const newArray = [];

    splitResponse.forEach((element) => {
      if (element === "\n") {
        // If the element is a single newline, add it as is.
        newArray.push(element);
      } else if (element.match(/^\s*$/)) {
        // If the element is multiple newlines (possibly surrounded by spaces), split further.
        element.split("\n").forEach((splitElement, index, arr) => {
          if (splitElement.trim().length > 0) {
            newArray.push(splitElement);
          }
          // Add a newline to the array for each split except the last if it's empty
          if (index < arr.length - 1) {
            newArray.push("\n");
          }
        });
      } else {
        // If the element is not a newline, add it as is.
        newArray.push(element);
      }
    });

    return newArray;
  };

  const addMessage = (chatID, message) => {
    setChatMessages((prevChatMessages) => {
      const newChatMessages = {
        [chatID]: [...(prevChatMessages[chatID] || []), message],
      };
      return newChatMessages;
    });
  };

  useEffect(() => {
    if (currentChatId && chatMessages[currentChatId]) {
      setCurrentMessages(chatMessages[currentChatId]);
    } else {
      setCurrentMessages([]);
    }
  }, [currentChatId, chatMessages]);

  useEffect(() => {
    if (isCreatingNewChat) {
      // Initialize chat with the initial message
      setChat([
        {
          text: "Hello there! My name is Kevin, and I am a personalized mental health chatbot trained in positive psychology. How can I help you today?",
          from: "bot",
        },
      ]);
    }
  }, [isCreatingNewChat]);

  useEffect(() => {
    const el = document.getElementsByClassName("chat-container")[0];
    if (el) {
      el.scrollTop = el.scrollHeight;
    }

    const lastMessage = chat[chat.length - 1];

    // Only call getBotResponse if isCreatingNewChat is false
    if (!isCreatingNewChat && (!lastMessage || lastMessage.from !== "bot")) {
      setChat([
        {
          text: "Hello there! My name is Kevin, and I am a personalized mental health chatbot trained in positive psychology. How can I help you today?",
          from: "bot",
        },
      ]);
    }
  }, [chat, isCreatingNewChat]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!currentChatId && !chatGoalAndStyle.chatGoal && !chatGoalAndStyle.chatStyle) {
      // User is not in a chat session and have not selected a chat goal and/or chat style for a new chat yet
      setShowChatGoalCards(true);
    } else if (!currentChatId && chatGoalAndStyle.chatGoal && chatGoalAndStyle.chatStyle) {
      // createSessionAndSendChat executes in this case
      // User is not in a chat session, but they've just selected the chat goal and style
      // The useEffect triggers after they select the goal and style, typed a message and hit submit -
      // It will call createSessionAndSendChat, which creates a new session and
      // automatically sends their chat
    } else {
      await sendChat();
    }
  };

  useEffect(() => {
    createSessionAndSendChat();
  }, [chatGoalAndStyle, submitButtonUsedForNewChatSession]);

  const createSessionAndSendChat = async () => {
    const trimmedText = text.trim();
    if (trimmedText !== "") {
      if (
        !currentChatId &&
        submitButtonUsedForNewChatSession &&
        chatGoalAndStyle.chatGoal &&
        chatGoalAndStyle.chatStyle
      ) {
        try {
          const newChatID = await onCreateNewChatSession(chatGoalAndStyle);
          await sendChat(newChatID);
        } catch (error) {
          console.error("Error creating new chat:", error.message);
        }
      }
    }
  };

  /**
   * Returns true if the title for chatID is NOT set, false if it is set
   */
  const chatTitleNotSet = (chatID) => {
    const currChat = allChats.find((chat) => chat.id === chatID);
    const hasTitle = currChat && "title" in currChat && currChat.title;
    return !hasTitle;
  };

  /**
   * Sends a chat message to the chatbot.
   * If the chat session's title is not set, sets the chat title.
   */
  const sendChat = async (chatID = currentChatId) => {
    const trimmedText = text.trim();
    if (trimmedText !== "") {
      setChat((prevChat) => [...prevChat, { text: trimmedText, from: "user" }]);
      // Clear the input field
      setText("");
      const userMessage = trimmedText;
      addMessage(chatID, userMessage);

      // Set the chat title if it isn't set already
      if (chatTitleNotSet(chatID)) {
        try {
          const response = await setChatTitle(chatID, trimmedText);
          if (response.success) {
            // Set this chat title in the UI
            updateChatTitle(chatID, response.title);
          }
        } catch (error) {}
      }
      const botResponse = await getBotResponse(chatID);
      return botResponse;
    }
  };

  const onSubmit = (choices) => {
    // generatePDF(choices);
    toggleModalView(false);
  };

  const formatOverallTimestamp = () => {
    const options = {
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Intl.DateTimeFormat("en-US", options).format(overallTimestamp);
  };
  const resetChatbotState = (includeInitialMessage = true) => {
    setText("");
    setChat([
      ...(includeInitialMessage
        ? [
            {
              text: "Hello there! My name is Kevin, and I am a personalized mental health chatbot trained in positive psychology. How can I help you today?",
              from: "bot",
            },
          ]
        : []),
    ]);
  };

  useImperativeHandle(ref, () => ({
    resetChatbotState,
  }));

  const handleRegenerateResponse = () => {
    setChat((prevChat) => prevChat.filter((_, index) => index < prevChat.length - 1));
    getBotResponse();
  };

  // Sets height of text area
  useEffect(() => {
    const textareaElement = document.getElementById("textarea");
    const handleInput = () => {
      textareaElement.style.height = "auto";
      textareaElement.style.height = `${textareaElement.scrollHeight}px`;
    };
    textareaElement.addEventListener("input", handleInput);
    return () => {
      textareaElement.removeEventListener("input", handleInput);
    };
  }, []);

  // Manages the height of the text area by resetting the height when 'text' is empty
  useEffect(() => {
    // Manually adjust the size of the textarea based on the text content
    const textareaElement = document.getElementById("textarea");
    if (text && typeof text === "string" && text.trim() === "") {
      textareaElement.style.height = "45px";
    }
  }, [text]);

  /***** VOICE INTEGRATION *****/
  // prettier-ignore
  const { 
    checkMicrophoneAccess, 
    startRecording, 
    stopRecording, 
    speechToText, 
    textToSpeech, 
    stopAudioPlayback 
  } = VoiceInterface();
  /* 
    'displayName' is what appears in the voice dropdown selection menu. This can be set to anything.
    'internalName' is the data sent to openAI textToSpeech() and needs to match openAI voice model options. 
    See https://platform.openai.com/docs/guides/text-to-speech
    If changing this, must match the chatbotVoices variable in SettingsMenu.js
  */
  const chatbotVoices = [
    {
      displayName: "Alloy",
      internalName: "alloy",
    },
    {
      displayName: "Echo",
      internalName: "echo",
    },
    {
      displayName: "Fable",
      internalName: "fable",
    },
    {
      displayName: "Onyx",
      internalName: "onyx",
    },
    {
      displayName: "Nova",
      internalName: "nova",
    },
    {
      displayName: "Shimmer",
      internalName: "shimmer",
    },
  ];
  const [chatbotVoice, setChatbotVoice] = useState(
    localStorage.getItem("preferredVoice" || chatbotVoices[1].internalName)
  ); // Default chatbot voice option
  const [isRecording, setIsRecording] = useState(false); // Used to handle starting/stopping recording
  const [showVoiceOptions, setShowVoiceOptions] = useState(false); // Voice option dropdown menu toggle
  const [micAccessGrantedOnMount, setMicAccessGrantedOnMount] = useState(false); // See use in handleMicrophoneClick()
  const [shouldSendAfterTranscription, setShouldSendAfterTranscription] = useState(false); // Used to trigger sendChat() after audio transcription ends

  // Sets the chatbot voice to be used in audio playback
  const handleSelectChatbotVoice = (voice) => {
    setChatbotVoice(voice);
  };

  // Voice option dropdown menu
  const handleShowVoiceOptions = () => {
    if (stripeSubscriptionStatus === "Premium") {
      setShowVoiceOptions(!showVoiceOptions);
    } else {
      onBasicUserPremiumFeatureClick();
    }
  };

  // Main handler for recording user audio and sending transcribed text
  const handleMicrophoneClick = async () => {
    if (stripeSubscriptionStatus === "Premium") {
      try {
        // Check if user has granted mic permission
        const micAccessGranted = await checkMicrophoneAccess();

        // If not granted, alert them and do not start recording.
        if (!micAccessGranted) {
          alert("Microphone must be enabled to use this feature.");
          return;
        }

        if (!micAccessGrantedOnMount) {
          // This useState variable handles a bug when the user first grants permission to the mic,
          // it starts recording but does not write to the audio file until the second time recording.
          // Without doing this, the user grants mic permission, and it appears as if it is recording,
          // but stopping recording does not show any transcription.
          // This just skips the first recording attempt which fails.
          setMicAccessGrantedOnMount(true);
          return;
        }

        // If they're not recording and mic access is granted, start recording
        if (!isRecording && micAccessGranted) {
          setIsRecording(true);
          startRecording();
          // If they are recording, stop the recording and trigger the audio to text transcription
        } else if (isRecording) {
          setIsRecording(false);
          const userAudio = await stopRecording(); // Stop recording and get the audio
          const userAudioTranscriptionText = await speechToText(userAudio); // Transcribe audio to text
          setText(userAudioTranscriptionText.trim()); // Set the user's text field to the transcription
          setShouldSendAfterTranscription(true);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      // Callback function to dashboard to display pricing
      onBasicUserPremiumFeatureClick();
    }
  };

  useEffect(() => {
    setText(text);
  }, [text]);

  // Helper to handleMicrophoneClick() -  Used to automatically send the audio transcription once recording finishes
  useEffect(() => {
    if (shouldSendAfterTranscription && text.trim() !== "") {
      sendChat();
      setShouldSendAfterTranscription(false); // Reset the flag to prevent sending on every text change
    }
  }, [text, shouldSendAfterTranscription]);

  // For basic users: shows pricing menu
  // For premium users: toggles audio playback
  const handleSpeakerClick = () => {
    if (stripeSubscriptionStatus === "Premium") {
      setShowVoiceOptions(false);
      setChatbotSpeechEnabled(!chatbotSpeechEnabled);
    } else {
      onBasicUserPremiumFeatureClick();
    }
  };

  return (
    <div className="Appmob">
      {/* web */}
      <div className="App">
        <div className="main-container">
          <div className="chat-container">
            {selectedChatMessages.map((message, index) => (
              <div className={message.role === "user" ? "user" : "bot"} key={index}>
                {message.role === "user" ? (
                  <FaUserAlt className={`image ${isNightMode ? "night-mode" : ""}`} />
                ) : (
                  <img className={`image ${isNightMode ? "night-mode" : ""}`} src={aiAvatarImage} alt="AI Avatar" />
                )}
                <div>
                  {message.role === "system" ? (
                    <p className={`message-text ${isNightMode ? "night-mode-text" : ""}`}>
                      Hello there! My name is Kevin, and I am a personalized mental health chatbot trained in positive
                      psychology. How can I help you today?
                    </p>
                  ) : (
                    <p className={`message-text ${isNightMode ? "night-mode-text" : ""}`}>{message.content}</p>
                  )}
                </div>
              </div>
            ))}

            {selectedChatMessages.length !== 0
              ? chat.slice(1).map((chatMessage, index) => (
                  <div className={chatMessage.from} key={index}>
                    {chatMessage.from === "user" ? (
                      <FaUserAlt className={`image ${isNightMode ? "night-mode" : ""}`} />
                    ) : (
                      <img className={`image ${isNightMode ? "night-mode" : ""}`} src={aiAvatarImage} alt="AI Avatar" />
                    )}
                    <div>
                      <p className={` ${isNightMode ? "night-mode-text" : ""}`}>{chatMessage.text}</p>
                    </div>
                  </div>
                ))
              : chat.map((chatMessage, index) => (
                  <div className={chatMessage.from} key={index}>
                    {chatMessage.from === "user" ? (
                      <FaUserAlt className={`image ${isNightMode ? "night-mode" : ""}`} />
                    ) : (
                      <img className={`image ${isNightMode ? "night-mode" : ""}`} src={aiAvatarImage} alt="AI Avatar" />
                    )}
                    <div>
                      <p className={` ${isNightMode ? "night-mode-text" : ""}`}>{chatMessage.text}</p>
                    </div>
                  </div>
                ))}

            <div id="anchor"></div>
          </div>

          <form className="chat-form" onSubmit={handleFormSubmit}>
            <div className="input-container-button">
              <div>
                <button
                  className={`regenerate-button ${isNightMode ? "night-mode-regenerate" : ""}`}
                  type="button"
                  onClick={handleRegenerateResponse}
                >
                  <FaRedo className="regenerate-icon" />
                  Regenerate Response
                </button>
              </div>
              <div className="input-container-button2">
                <div className="textarea-container">
                  <textarea
                    type="text"
                    rows="1"
                    id="textarea"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder={`${isRecording ? "Recording... Click mic to send" : "Type something..."}`}
                    className={`scrollable-input ${isNightMode ? "night-mode-text" : ""}`}
                    style={{ height: "42px", maxHeight: "200px" }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault(); // Prevent the default action to avoid inserting a new line
                        handleFormSubmit(e); // Call the form submit handler directly or your custom function
                      }
                    }}
                  />
                  <div className={`microphone ${isNightMode ? "night-mode-icon" : ""}`}>
                    {isRecording ? (
                      <MdOutlineMic
                        className={`microphone-icon on ${isRecording ? "pulse" : ""}`}
                        onClick={handleMicrophoneClick}
                      />
                    ) : (
                      <MdOutlineMic
                        className={`microphone-icon off ${isRecording ? "pulse" : ""}`}
                        onClick={handleMicrophoneClick}
                      />
                    )}
                  </div>
                </div>
                <button type="submit" className={`submit-button ${isNightMode ? "night-mode-icon" : ""}`}>
                  <BiSend className="submit-icon" />
                  <span className="text">Submit</span>
                </button>
              </div>
            </div>
          </form>

          <div className={`chatbot-voice-control-container ${isNightMode ? "night-mode" : ""}`}>
            {chatbotSpeechEnabled ? (
              <div className="chatbot-voice-control-content">
                <div className="voice-control-icons-container">
                  <GiSpeaker
                    className={`chatbot-speaker-icon on ${isNightMode ? "night-mode-icon" : ""}`}
                    onClick={() => {
                      handleSpeakerClick();
                      stopAudioPlayback(); // Stop the audio playback
                    }}
                  />
                  <FaChevronDown
                    className={`chatbot-speaker-icon chevron ${showVoiceOptions ? "rotate" : ""} ${
                      isNightMode ? "night-mode-icon" : ""
                    }`}
                    onClick={handleShowVoiceOptions}
                  />
                </div>
                {/* {showVoiceOptions && ( */}
                <div className={`chatbot-voice-options-container ${showVoiceOptions ? "open" : "closed"}`}>
                  {chatbotVoices.map((voice, index) => (
                    <div
                      key={index}
                      className={`chatbot-voice-option ${
                        voice.internalName === chatbotVoice ? "selected" : "unselected"
                      }`}
                      onClick={() => handleSelectChatbotVoice(voice.internalName)}
                    >
                      <p>{voice.displayName}</p>
                    </div>
                  ))}
                </div>
                {/* // )} */}
              </div>
            ) : (
              <div className="chatbot-voice-control-content">
                <GiSpeakerOff
                  className={`chatbot-speaker-icon off ${isNightMode ? "night-mode-icon" : ""}`}
                  onClick={handleSpeakerClick}
                />
              </div>
            )}
          </div>
        </div>

        <div id="modal"></div>
        <Modal
          isModalOpen={isModalOpen}
          onClose={() => {
            toggleModalView(false);
          }}
          onSubmit={onSubmit}
        />
      </div>
    </div>
  );
}

//export default Chatbot;

export default React.forwardRef(Chatbot);
