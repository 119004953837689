import React, { useState, useEffect } from "react";
import { FaUser, FaPen } from "react-icons/fa6";
import logoImage from "../images/logo.png";
import "../App.css";

const ChatbotAnimation = () => {
  /* Handles state for the typing animation and chatbot response.
     These animations are only shown once, and do not repeat.
  */
  const [isTextTyped, setIsTextTyped] = useState(false);
  const [isResponseVisible, setIsResponseVisible] = useState(false);
  /*
     Effect that only triggers once the voice integration section comes into the 
     user's viewport (ie. They scroll here for the first time.)
     Triggers a typewriter effect for the user input that happens only once,
     then the chatbot response fades in afterwards.
  */
  useEffect(() => {
    // Only run the animation once the subsection enters the viewport
    const isSS2InViewport = () => {
      const subsection2 = document.querySelector(".chatbot-animation-content");
      const subsection2Rect = subsection2.getBoundingClientRect();
      return subsection2Rect.top < window.innerHeight && subsection2Rect.bottom >= 0;
    };

    // Checks if subsection is in viewport and that the animation hasn't been played already
    const handleScroll = () => {
      if (isSS2InViewport() && !isTextTyped) {
        // Text typing animation
        setIsTextTyped(true);

        // After a delay, show chatbot the response container
        setTimeout(() => {
          setIsResponseVisible(true);
        }, 2000);
      }
    };

    // Scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isTextTyped]);
  return (
    // <div className="subsection-content-container">
    <div className="chatbot-animation-content">
      {/* User Query Box */}
      <div className="chatbot-question-container">
        <FaUser className="user-icon" />
        <div className="chatbot-question-input">{isTextTyped && <p>I feel very tired and stressed...</p>}</div>
        <FaPen className="pen-icon" />
      </div>
      {/* Chatbot Response */}
      <div className={`chatbot-response-container ${isResponseVisible ? "visible" : ""}`}>
        <img src={logoImage} alt="Blue Guardian"></img> {/* BG Icon */}
        <div className="chatbot-response">
          {/* Chat Response Field */}
          <p>
            Hey there! I know things might feel a bit overwhelming right now, but guess what? You've got this! Take a
            deep breath and remember that challenges are just opportunities in disguise. You're stronger and more
            capable than you realize.
            <br />
            <br />
            Break down those tasks, tackle them one by one, and celebrate each small victory. You're making progress,
            and that's something to be proud of! Don't forget to take breaks, do something you enjoy, and surround
            yourself with positive vibes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChatbotAnimation;
