import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Header from "./Header";
import Banner from "./Banner";
import HeroSection from "./HeroSection";
import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import SignUpCTASection from "./SignUpCTASection";
import Testimonials from "./Testimonials";
import SocialProofPopup from "./SocialProofPopup";
import Footer from "./Footer";
import TopButton from "./TopButton";

function Main() {
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.replace("#", "");
      scrollToSection(sectionId);
    }
  }, [location]);

  return (
    // prettier-ignore
    <div>
      <Header scrollToSection={scrollToSection} />
      {/* <Banner id="banner" text="We aim to be the empathetic support system in everyone's pocket" buttonText="VIEW DEMO"/> */}
      <div id="hero-section"><HeroSection/></div>
      <div id="section2"><Section2 /></div> {/* Features */}
      <div id="section3"><Section3 /></div> {/* Pricing */}
      <div id="testimonials"><Testimonials/></div>
      <div id="section1"><Section1 /></div> {/* FAQ */}
      <div id="section4"><Section4 /></div> {/* Team */}
      <div id="signup-cta"><SignUpCTASection/></div>
      <div id="social-proof-popup"><SocialProofPopup/></div>
      <Footer scrollToSection={scrollToSection}/>
      <TopButton/>
    </div>
  );
}
export default Main;
