import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import ChatbotAnimation from "./ChatbotAnimation";
import "../App.css";

function Main() {
  const navigate = useNavigate();

  return (
    <div className="hero-section-content-container">
      <h1>
        AI-Powered <span>Mental Health Coach</span> Designed for Youth
      </h1>
      <p>
        Early detection of mental health issues. <br />
        Personalized support tailored to your unique well-being journey.
      </p>
      <button onClick={() => navigate("/signup")}>FREE TRIAL</button>
      <div className="chatbot-animation-container">
        <ChatbotAnimation />
      </div>
    </div>
  );
}

export default Main;
