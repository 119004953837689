import React, { useState, useRef } from "react";
import { FaXmark, FaChevronLeft } from "react-icons/fa6";
import "./Dashboard.css";
import "../App.css";
import cardImg1 from "../images/self-esteem.png";
import cardImg2 from "../images/reduce-stress.png";
import cardImg3 from "../images/relationships.png";
import PricingPlan from "./PricingPlan";

/**
 * After a user hits "New Chat" in Dashboard, opens the chat goal cards.
 * Responsible for handling selection of chat goal and chat style, then passing
 * these back to the dashboard for creating a new chat.
 *
 * @param {function} onGetChatGoalAndStyle callback function that passes chatGoal and chatStyle back to caller
 * @param {function} onCloseChatGoalCards callback function that handles closing the chat goal card popup
 * @param {boolean} isNightMode if true, use night mode styling
 * @returns the chat goal cards in the dashboard, and the chat goal and chat style for a new chat.
 */
const ChatGoalCards = ({
  onGetChatGoalAndStyle,
  onCloseChatGoalCards,
  onUpgradeClick,
  isNightMode,
  apiResponsePayment,
}) => {
  const [chatGoal, setChatGoal] = useState(0);
  const [chatStyle, setChatStyle] = useState(0);
  const [displayedCards, setDisplayedCards] = useState("goal");
  const [enableCreateChatButton, setEnableCreateChatButton] = useState(false);
  const [showPricingPopupChatGoalCards, setShowPricingPopupChatGoalCards] = useState(false);
  const [showUpgradeButton, setShowUpgradeButton] = useState(null);
  const [showPricingPopupGoal, setShowPricingPopupGoal] = useState(true);

  const goalCards = [
    {
      img: cardImg1,
      h: "Build Self-Esteem",
      desc: "Build Acceptance, Resilience, Self-Reflection",
      apiData: 1,
    },
    {
      img: cardImg2,
      h: "Reduce Stress",
      desc: "Reduce Relationship Strain, Health Issues, Work-Related Stress",
      apiData: 2,
    },
    {
      img: cardImg3,
      h: "Interpersonal Relationships",
      desc: "Improve Communication, Social Skills, Emotion Management",
      apiData: 3,
    },
  ];
  // Data for cards after being clicked.
  const styleCards = [
    {
      h: "Encouragement & Positivity",
      desc: "You're not alone. Let's explore ways to work through this.",
      apiData: 1,
    },
    {
      h: "Non-Directive Approach",
      desc: "I'm here to listen and support you. How would you like to approach this?",
      apiData: 2,
    },
    {
      h: "Resource & Information Sharing",
      desc: "I can provide coping strategies or information on finding support.",
      apiData: 3,
    },
  ];

  const handleCloseChatGoalCards = () => {
    setChatGoal(0);
    setChatStyle(0);
    setEnableCreateChatButton(false);
    onCloseChatGoalCards(false);
  };

  const handleBackClick = () => {
    setDisplayedCards("goal");
    setEnableCreateChatButton(false);
    setChatGoal(0);
    setChatStyle(0);
  };

  const handleGoalCardClick = (selectedChatGoal) => {
    setChatGoal(selectedChatGoal);
    setDisplayedCards("style");
  };

  const newChatButtonRef = useRef(null); // So new chat button can be scrolled into view when style is picked
  const handleStyleCardClick = (selectedChatStyle) => {
    setChatStyle(selectedChatStyle);
    newChatButtonRef.current?.scrollIntoView({ behavior: "smooth" }); // Scrolls to create chat button when style is picked
    setEnableCreateChatButton(true);
  };

  const returnChatGoalAndStyle = (selectedChatGoal, selectedChatStyle) => {
    setChatGoal(selectedChatGoal);
    setChatStyle(selectedChatStyle);
    onCloseChatGoalCards(false);
    onGetChatGoalAndStyle({ chatGoal: chatGoal, chatStyle: chatStyle });
  };

  const handleUpgradeClick = () => {
    onCloseChatGoalCards(true);
    onUpgradeClick(true);
  };

  return (
    <div className="popup-container">
      {showPricingPopupGoal && (
        <div className={`popup-content-wide ${isNightMode ? "popup-night-mode" : ""}`}>
          <FaXmark
            className={`popup-settings-close ${isNightMode ? "night-mode-icon" : ""}`}
            onClick={handleCloseChatGoalCards}
          />
          {displayedCards === "style" && (
            <FaChevronLeft
              className={`popup-settings-back ${isNightMode ? "night-mode-icon" : ""}`}
              onClick={handleBackClick}
            />
          )}

          <div className={`card-header ${isNightMode ? "night-mode-text" : ""}`}>
            {displayedCards === "goal" ? <h2>Select Your Goal</h2> : <h2>Select Your Communication Style</h2>}
            <p>
              A study by Dominican University found that people who set goals are <b>43 percent</b> more likely to
              achieve them.
            </p>
          </div>

          <div className="card-container">
            {displayedCards === "goal" &&
              goalCards.map((item, index) => (
                <div
                  className={`card1 ${isNightMode ? "night-mode-card" : ""} ${
                    !apiResponsePayment && (index === 0 || index === 2) ? "disabled-card" : ""
                  }`}
                  key={index}
                  onClick={
                    !apiResponsePayment && (index === 0 || index === 2) ? null : () => handleGoalCardClick(item.apiData)
                  }
                >
                  {!apiResponsePayment && (index === 0 || index === 2) && (
                    <div className="disabled-card-overlay">
                      <div className="upgrade-button" onClick={handleUpgradeClick}>
                        Upgrade
                      </div>
                    </div>
                  )}
                  <div className="card1-img-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="163" height="163" viewBox="0 0 163 163" fill="none">
                      <circle cx="81.7009" cy="81.2254" r="75" fill="#C7D9F3" fillOpacity="0.3" />
                    </svg>
                    <img src={item.img} alt="Friends"></img>
                  </div>
                  <div className="card1-info">
                    <h3>{item.h}</h3>
                    <p>{item.desc}</p>
                  </div>
                </div>
              ))}

            {displayedCards === "style" &&
              styleCards.map((item, index) => (
                <div
                  className={`card2 ${isNightMode ? "night-mode-card" : ""} ${
                    !apiResponsePayment && (index === 0 || index === 2) ? "disabled-card" : ""
                  } ${item.apiData === chatStyle ? "selected" : ""}`}
                  key={index}
                  onClick={
                    !apiResponsePayment && (index === 0 || index === 2)
                      ? null
                      : () => handleStyleCardClick(item.apiData)
                  }
                >
                  {!apiResponsePayment && (index === 0 || index === 2) && (
                    <div className="disabled-card-overlay">
                      <div className="upgrade-button" onClick={handleUpgradeClick}>
                        Upgrade
                      </div>
                    </div>
                  )}
                  <div className="speech-bubble">
                    <p>"{item.desc}"</p>
                    <svg xmlns="http://www.w3.org/2000/svg" width="180" height="180" viewBox="0 0 136 136" fill="white">
                      <path
                        d="M38.6469 129.109C32.9099 132.471 21.9812 137.118 10.8393 132.4L2.84838 129.016L17.1794 112.98C8.26268 102.968 2.51318 90.5462 0.65501 77.2787C-1.20316 64.0113 0.913098 50.4916 6.73723 38.4224C12.5614 26.3532 21.8329 16.2741 33.3843 9.45455C44.9357 2.63499 58.2503 -0.620093 71.6513 0.0992297C85.0523 0.818553 97.9404 5.48012 108.693 13.4969C119.445 21.5136 127.58 32.527 132.074 45.1501C136.568 57.7731 137.22 71.4414 133.947 84.4334C130.674 97.4255 123.624 109.16 113.683 118.16C103.699 127.253 91.2175 133.156 77.8447 135.107C64.472 137.058 50.8201 134.969 38.6469 129.109ZM117.258 23.2855C111.392 16.8259 104.308 11.5831 96.4121 7.85656C88.516 4.12999 79.9618 1.99268 71.2381 1.56666C62.5143 1.14065 53.792 2.4343 45.5692 5.37373C37.3464 8.31315 29.7842 12.8408 23.3146 18.698C16.845 24.5552 11.5946 31.6272 7.86339 39.5103C4.13218 47.3933 1.99323 55.9328 1.56867 64.6411C1.1441 73.3494 2.44225 82.0559 5.38898 90.2634C8.33572 98.4708 12.8733 106.018 18.7426 112.475L19.1961 112.974L5.36292 128.454L11.4254 131.021C22.1862 135.577 32.8346 130.844 38.2243 127.617L38.5727 127.408L38.9382 127.586C52.8317 134.325 68.6419 136.025 83.6546 132.395C98.6672 128.765 111.946 120.032 121.21 107.694C130.475 95.3559 135.148 80.1833 134.426 64.7808C133.705 49.3784 127.635 34.707 117.258 23.2855Z"
                        fill="#3081EE"
                      />
                    </svg>
                  </div>
                  <h3>{item.h}</h3>
                </div>
              ))}
          </div>

          {displayedCards === "style" && (
            <button
              className={`create-chat-button ${enableCreateChatButton ? "enabled" : "disabled"} 
              ${isNightMode ? "night-mode-button" : ""}`}
              onClick={() => returnChatGoalAndStyle(chatGoal, chatStyle)}
              disabled={!enableCreateChatButton}
              ref={newChatButtonRef}
            >
              Start New Chat
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ChatGoalCards;
