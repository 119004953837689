import React from "react";
import { FaStar, FaStarHalf } from "react-icons/fa6";
import Carousel from "./Carousel";
import "../App.css";

const Testimonials = () => {
  /**
   * text: the testimonial
   * fullStars: the number of full stars out of 5
   * halfStar: if the review has a half star, ie: 4.5/5 is 4 fullStars and 1 halfStar
   * name: name of person who gave testimonial
   * location: location of person
   * age: age of person
   */
  const testimonials = [
    {
      text: "Using the AI mental health chatbot felt like having a supportive friend who truly understood me, guiding me through tough times with empathy and wisdom.",
      fullStars: 4,
      halfStar: 1,
      name: "Sophie L",
      location: "Carolina",
      age: "22",
    },
    {
      text: "The convenience of accessing a 24/7 AI mental health companion has been a game-changer, providing timely support whenever I needed someone to talk to.",
      fullStars: 5,
      halfStar: 0,
      name: "Michael O",
      location: " California",
      age: "18",
    },
    {
      text: "I was skeptical at first, but the AI mental health chatbot surprised me with its ability to offer personalized coping strategies that genuinely improved my mental well-being.",
      fullStars: 5,
      halfStar: 0,
      name: "Priya N",
      location: "Texas",
      age: "19",
    },
    {
      text: "The AI chatbot helped me break down complex emotions into manageable thoughts, offering insightful reflections that empowered me to navigate life's challenges with newfound resilience.",
      fullStars: 4,
      halfStar: 1,
      name: "Aya K",
      location: "Alberta",
      age: "21",
    },
    {
      text: "Interacting with the AI mental health chatbot was surprisingly comforting, providing a non-judgmental space for self-reflection and fostering a sense of inner peace during difficult moments.",
      fullStars: 5,
      halfStar: 0,
      name: "Mateo L",
      location: "Arkansas",
      age: "19",
    },
    {
      text: "The AI mental health chatbot seamlessly integrates into my daily routine, offering practical coping tools and affirmations that have become an invaluable source of positivity in my life.",
      fullStars: 4,
      halfStar: 1,
      name: "Jennifer R",
      location: "New Jersey",
      age: "23",
    },
  ];

  const carouselItems = testimonials.map((testimonial, index) => (
    <div key={index} className="testimonial-content">
      <p className="testimonial-text">{testimonial.text}</p>
      <div className="testimonial-info">
        <div className="stars">
          {Array.from({ length: testimonial.fullStars }, (_, i) => (
            <FaStar key={i} />
          ))}
          {testimonial.halfStar === 1 && <FaStarHalf />}
        </div>
        <p className="testimonial-name-age">
          {testimonial.name}, {testimonial.age}
        </p>
        <p className="testimonial-location">{testimonial.location}</p>
      </div>
    </div>
  ));

  const componentID = "testimonials";

  return (
    <section className="testimonials-section">
      <header className="testimonials-header">
        Why People
        <br />
        <span>Love Using Blue Guardian</span>
      </header>
      <Carousel items={carouselItems} componentID={componentID} scrollSnapType="start" />
    </section>
  );
};

export default Testimonials;
