import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaXmark } from "react-icons/fa6";
import logoImage from "../images/logo.png";

const Header = ({ scrollToSection }) => {
  const navigate = useNavigate();

  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleScroll = (section) => {
    if (window.location.pathname.includes("/blog")) {
      navigate(`/#${section}`);
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      scrollToSection(section);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo-container">
          <Link className="navbar-logo" to="/">
            <img src={logoImage} alt="Logo"></img>
            <h3>BLUE GUARDIAN</h3>
          </Link>
        </div>
        <div onClick={handleShowNavbar} className="mobile-menu-icon">
          {showNavbar ? <FaXmark /> : <FaBars />}
        </div>
        <div className={`navbar-elements ${showNavbar && `active`}`}>
          <div className="navbar-links">
            <button onClick={() => handleScroll("section2")}>Features</button>
            <button onClick={() => handleScroll("section3")}>Pricing</button>
            <button onClick={() => handleScroll("section1")}>Product</button>
            <button onClick={() => handleScroll("section4")}>Company</button>
            <button onClick={() => navigate("/blog")}>Blog</button>
          </div>
          <div className="navbar-signup-login">
            <button className="login-button" onClick={() => navigate("/login")}>
              Sign In
            </button>
            <button className="signup-button" onClick={() => navigate("/signup")}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
