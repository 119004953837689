import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { FaArrowLeft, FaGear, FaCircleCheck, FaEye, FaEyeSlash } from "react-icons/fa6";
import { FaUserAlt, FaMicrophone, FaDatabase, FaChevronDown } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import AuthService from "../Services/AuthService";

const SettingsMenu = ({
  isNightMode,
  onSetNightMode,
  userDetails,
  currentPlanStatus,
  onManageSubscriptionClick,
  logOut,
  onDeleteAllChats,
  onLinkGoogleAccount,
}) => {
  const submenus = {
    GENERAL: 0,
    ACCOUNT: 1,
    DATA_CONTROLS: 2,
    VOICE: 3,
    CHANGE_PASSWORD: 4,
    DELETE_CHATS: 5,
    DELETE_USER: 6,
  };
  const navigate = useNavigate();
  const [selectedSubmenu, setSelectedSubmenu] = useState(submenus.GENERAL);
  const [selectedDropdown, setSelectedDropdown] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [submitResultMessage, setSubmitResultMessage] = useState("");
  const [manageSubButtonEnabled, setManageSubButtonEnabled] = useState(true);
  const [submitMessageError, setSubmitMessageError] = useState(false);
  const [preferredVoice, setPreferredVoice] = useState("");
  const [deleteAllChatsResult, setDeleteAllChatsResult] = useState({ success: null, message: "" });
  const [linkGoogleAccountResult, setLinkGoogleAccountResult] = useState({ success: null, message: "" });
  const { manageSubscriptionStripe, changePasswordDashboard, logoutUser, deleteUser } = AuthService();

  /*
   * 'displayName' is what appears in the voice dropdown selection menu. This can be set to anything.
   * 'internalName' is the data sent to openAI textToSpeech() and needs to match openAI voice model options.
   * See https://platform.openai.com/docs/guides/text-to-speech
   * If changed, must match the chatbotVoices variable in Chatbot.js
   */
  const chatbotVoices = [
    {
      displayName: "Alloy",
      internalName: "alloy",
    },
    {
      displayName: "Echo",
      internalName: "echo",
    },
    {
      displayName: "Fable",
      internalName: "fable",
    },
    {
      displayName: "Onyx",
      internalName: "onyx",
    },
    {
      displayName: "Nova",
      internalName: "nova",
    },
    {
      displayName: "Shimmer",
      internalName: "shimmer",
    },
  ];

  /**
   * Sets the preferred voice from local storage when the component mounts
   */
  useEffect(() => {
    let prefVoice = localStorage.getItem("preferredVoice");
    if (prefVoice) {
      // Find the voice object in chatbotVoices array that matches the internalName, then get the displayName
      prefVoice = chatbotVoices.find((voice) => voice.internalName === prefVoice).displayName;
      setPreferredVoice(prefVoice);
    } else {
      setPreferredVoice("Echo");
    }
  }, []);

  /**
   * Resets the settings when the submenu is changed or when settings is closed
   */
  const resetSettings = () => {
    setSelectedDropdown("");
    setCurrentPassword("");
    setNewPassword("");
    setShowCurrentPassword(false);
    setShowNewPassword(false);
    setSubmitResultMessage("");
    setSubmitMessageError(false);
    setDeleteAllChatsResult({ success: null, message: "" });
    setLinkGoogleAccountResult({ success: null, message: "" });
  };

  /**
   * Handles the submenu click event to show the selected submenu
   *
   * @param {string} submenuItem value from the submenus object
   */
  const handleSubmenuClick = (submenuItem) => {
    resetSettings();
    if (submenuItem !== selectedSubmenu) {
      setSelectedSubmenu(submenuItem);
    }
  };

  /**
   * Handles updating the theme when the user selects light or dark mode
   *
   * @param {boolean} mode False means light mode, True means dark mode
   */
  const handleSetNightMode = (mode) => {
    const theme = mode === "light" ? "light" : "dark";
    localStorage.setItem("theme", theme);
    onSetNightMode(theme === "light" ? false : true);
  };

  /**
   * Handles showing and hiding the dropdown menus. Will hide all dropdowns if the same dropdown is clicked again.
   * Enforces only one dropdown at a time is open.
   *
   * @param {string} dropdown The dropdown menu to show or hide
   */
  const handleShowDropdown = (dropdown) => {
    if (dropdown === "hideAll" || dropdown === selectedDropdown) {
      setSelectedDropdown("");
      return;
    } else if (dropdown !== selectedDropdown) {
      setSelectedDropdown(dropdown);
      return;
    }
  };

  /**
   * Logs out the user and clears all cookies, session storage, and local storage
   */
  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
    }
  };

  /**
   * Handles the logout click event to log the user out and redirect to the login page
   */
  const handleLogoutClick = async () => {
    try {
      const isLogout = await logoutUser();

      if (isLogout) {
        logOut();
        clearCookies();
        sessionStorage.clear();
        localStorage.removeItem("selectedSessionId");
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  /**
   * Handles the delete user click event to delete the user and log them out
   */
  const handleConfirmDeleteUserClick = async () => {
    try {
      const isDeleted = await deleteUser();
      localStorage.clear();
      if (isDeleted) {
        handleLogoutClick();
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  /**
   * Handles the delete chats click event to delete all chats
   */
  const handleConfirmDeleteChatsClick = async () => {
    try {
      const result = await onDeleteAllChats();
      setDeleteAllChatsResult({ success: result.success, message: result.message });
      console.log(result);
    } catch (error) {
      setDeleteAllChatsResult({ success: false, message: "An error occurred deleting your chats. Please try again." });
      console.error("Error deleting chats:", error);
    }
  };

  /**
   * Handles the manage subscription click event to redirect the user to the subscription management page (if premium),
   * or show the pricing plan (if basic)
   */
  const handleManageSubscriptionClick = async () => {
    console.log("currentPlanStatus", currentPlanStatus);

    if (currentPlanStatus === "Premium") {
      try {
        const response = await manageSubscriptionStripe();
        if (response.url) {
          setManageSubButtonEnabled(false);
          window.location.href = response.url;
        } else {
          setManageSubButtonEnabled(true);
        }
      } catch (error) {
        console.error("Error fetching stripe status:", error);
      }
    } else {
      onManageSubscriptionClick();
    }
  };

  /**
   * Handles the password change event to update the current password state and manage password validation
   *
   * @param {Event} e the event for the password input field
   */
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);

    // Password validation logic
    const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(newPassword);

    if (!isValidPassword && newPassword.length > 0) {
      setSubmitMessageError(true);
      setSubmitResultMessage(
        "Password must have at least 8 characters, one uppercase letter, one number, and one symbol."
      );
    } else {
      setSubmitMessageError(false);
      setSubmitResultMessage("");
    }
  };

  /**
   * Handles the password change submit event to update the user's password
   */
  const handleChangePasswordSubmit = async () => {
    setSubmitResultMessage(() => "");
    setSubmitMessageError(() => false);
    if (currentPassword === "" || (newPassword === "" && !submitMessageError)) {
      setSubmitMessageError(() => true);
      setSubmitResultMessage(() => "Please fill out all fields.");
      return;
    }
    let response;
    try {
      response = await changePasswordDashboard(currentPassword, newPassword, userDetails.uid);
      if (response.success) {
        setSubmitMessageError(() => false);
        setSubmitResultMessage(() => "Password changed successfully!");
      }
    } catch (error) {
      if (error.response.status === 401) {
        // Incorrect current password
        setSubmitMessageError(() => true);
        setSubmitResultMessage(() => "Password change failed. Please check your current password and try again.");
      } else if (error.response.status === 500) {
        // Server error
        setSubmitMessageError(() => true);
        setSubmitResultMessage(() => "An error occurred changing the password. Please try again.");
      } else {
        // Network error
        setSubmitMessageError(() => true);
        setSubmitResultMessage(() => "An unexpected error occurred. Please try again.");
      }
      console.error("Error changing password: ", error);
    }
  };

  /**
   * Sets the preferred voice for the chatbot into local storage (internal name) and state (display name)
   * Internal name is used for local storage because Chatbot.js fetches this value and applies it to the chatbot
   * Display name is used for preferredVoice because this is what is displayed in the settings menu
   *
   * @param voice an object from the chatbotVoices array
   */
  const handleSetPreferredVoice = (voice) => {
    localStorage.setItem("preferredVoice", voice.internalName);
    setPreferredVoice(voice.displayName);
  };

  /**
   * Handles linking the user's Google account to their existing account
   */
  const handleLinkGoogleAccount = async () => {
    try {
      const result = await onLinkGoogleAccount();
      if (result === true) {
        // Successfully linked Google account
        setLinkGoogleAccountResult((prevState) => ({
          ...prevState,
          success: true,
          message: "Google account linked successfully!",
        }));
      } else if (result === false) {
        // Their sign up email didn't match their Google account email
        setLinkGoogleAccountResult((prevState) => ({
          ...prevState,
          success: false,
          message: "To link your Google account, it must use the same email as the one you signed up with.",
        }));
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use" || error.code === "auth/credential-already-in-use") {
        // Account is already linked
        setLinkGoogleAccountResult((prevState) => ({
          ...prevState,
          success: false,
          message: "This Google account is already linked to another user.",
        }));
      } else if (error.code === "auth/popup-closed-by-user" || error.code === "auth/cancelled-popup-request") {
        // Don't need to show a result message if they just closed the popup
        setLinkGoogleAccountResult((prevState) => ({
          ...prevState,
          success: null,
          message: "",
        }));
      } else {
        // Some other error occured
        setLinkGoogleAccountResult((prevState) => ({
          ...prevState,
          success: false,
          message: "An error occurred linking your Google account.",
        }));
      }
      console.error("Error linking Google account:", error);
    }
  };

  return (
    <div id="settings" className={`settings-container ${isNightMode ? "night-mode" : ""}`}>
      <div className="settings-header">
        <h3>Settings</h3>
      </div>
      <div className="settings-content-container">
        <div className="settings-submenu">
          <div
            className={`settings-submenu-item ${selectedSubmenu === submenus.GENERAL ? "selected" : ""}`}
            onClick={() => handleSubmenuClick(submenus.GENERAL)}
          >
            <FaGear className="settings-icon" />
            General
          </div>
          <div
            className={`settings-submenu-item ${
              selectedSubmenu === submenus.ACCOUNT ||
              selectedSubmenu === submenus.PASSWORD ||
              selectedSubmenu === submenus.DELETE_USER
                ? "selected"
                : ""
            }`}
            onClick={() => handleSubmenuClick(submenus.ACCOUNT)}
          >
            <FaUserAlt className="settings-icon" />
            Account
          </div>
          <div
            className={`settings-submenu-item ${
              selectedSubmenu === submenus.DATA_CONTROLS || selectedSubmenu === submenus.DELETE_CHATS ? "selected" : ""
            }`}
            onClick={() => handleSubmenuClick(submenus.DATA_CONTROLS)}
          >
            <FaDatabase className="settings-icon" />
            Data Controls
          </div>
          <div
            className={`settings-submenu-item ${selectedSubmenu === submenus.VOICE ? "selected" : ""}`}
            onClick={() => handleSubmenuClick(submenus.VOICE)}
          >
            <FaMicrophone className="settings-icon" />
            Voice
          </div>
        </div>

        {selectedSubmenu === submenus.GENERAL && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action">
              <p>Theme</p>
              <div>
                <div className="settings-dropdown">
                  <div className="settings-dropdown-preview" onClick={() => handleShowDropdown("Theme")}>
                    {isNightMode ? <p>Dark</p> : <p>Light (Default)</p>}
                    <FaChevronDown className="settings-icon dropdown" />
                  </div>
                  <div
                    className={`settings-dropdown-options ${selectedDropdown === "Theme" ? "visible" : "hidden"} theme`}
                  >
                    <p className={`${isNightMode ? "" : "selected"}`} onClick={() => handleSetNightMode("light")}>
                      Light (Default)
                    </p>
                    <p className={`${isNightMode ? "selected" : ""}`} onClick={() => handleSetNightMode("dark")}>
                      Dark
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="settings-submenu-action">
              <p>Manage Subscription</p>
              <div>
                <button
                  className="settings-action-button"
                  onClick={handleManageSubscriptionClick}
                  disabled={!manageSubButtonEnabled}
                >
                  Manage
                </button>
              </div>
            </div>
            <div className="settings-submenu-action">
              <p>Logout</p>
              <div>
                <button className="settings-action-button" onClick={handleLogoutClick}>
                  Logout
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.ACCOUNT && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action google">
              <div className="google-submenu-action-inner">
                <p>Signed in as {userDetails.email}</p>
                <button
                  className="settings-action-button"
                  disabled={userDetails.provider === "Google"}
                  onClick={handleLinkGoogleAccount}
                >
                  {/* prettier-ignore */}
                  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/>
                  </svg>
                  <p>{userDetails.provider === "Google" ? "Account Linked" : "Link Google Account"}</p>
                </button>
              </div>

              <p className={`submit-message ${linkGoogleAccountResult.success ? "success" : "error"}`}>
                {linkGoogleAccountResult.message}
              </p>
            </div>
            <div className="settings-submenu-action">
              <p>Change Password</p>
              <button className="settings-action-button" onClick={() => setSelectedSubmenu(submenus.CHANGE_PASSWORD)}>
                Change
              </button>
            </div>
            <div className="settings-submenu-action">
              <p>Delete Account</p>
              <div>
                <button
                  className="settings-action-button danger"
                  onClick={() => setSelectedSubmenu(submenus.DELETE_USER)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.DATA_CONTROLS && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action">
              <p>Delete All Chats</p>
              <div>
                <button
                  className="settings-action-button danger"
                  onClick={() => setSelectedSubmenu(submenus.DELETE_CHATS)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.VOICE && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action">
              <p>Preferred Voice</p>
              <div>
                <div className="settings-dropdown">
                  <div className="settings-dropdown-preview" onClick={() => handleShowDropdown("Voice")}>
                    {preferredVoice ? <p>{preferredVoice}</p> : <p>Echo</p>}
                    <FaChevronDown className="settings-icon dropdown" />
                  </div>
                  <div
                    className={`settings-dropdown-options ${selectedDropdown === "Voice" ? "visible" : "hidden"} voice`}
                  >
                    {chatbotVoices.map((voice, index) => (
                      <p
                        className={`${preferredVoice === voice.displayName ? "selected" : ""}`}
                        key={index}
                        onClick={() => handleSetPreferredVoice(voice)}
                      >
                        {voice.displayName}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.CHANGE_PASSWORD && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action secondary">
              <FaArrowLeft className="settings-back-arrow" onClick={() => handleSubmenuClick(submenus.ACCOUNT)} />
              Change Password
            </div>
            <div className="secondary-submenu-action-content">
              <div className="input-container">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  id="current-password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder=" "
                  required
                />
                <label className="input-label" htmlFor="current-password">
                  Current Password
                </label>
                <span className="eye-toggle-icon" onClick={() => setShowCurrentPassword(!showCurrentPassword)}>
                  {showCurrentPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              <div className="input-container">
                <input
                  type={showNewPassword ? "text" : "password"}
                  id="new-password"
                  value={newPassword}
                  onChange={handlePasswordChange}
                  placeholder=" "
                  required
                />
                <label className="input-label" htmlFor="new-password">
                  New Password
                </label>
                <span className="eye-toggle-icon" onClick={() => setShowNewPassword(!showNewPassword)}>
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              <div className="settings-action-icon-container">
                {submitResultMessage ? (
                  <p className={`submit-message ${submitMessageError ? "error" : ""}`}>{submitResultMessage}</p>
                ) : (
                  <p></p>
                )}
                <FaCircleCheck className="settings-action-icon" onClick={handleChangePasswordSubmit} />
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.DELETE_USER && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action secondary">
              <FaArrowLeft className="settings-back-arrow" onClick={() => handleSubmenuClick(submenus.ACCOUNT)} />
              Delete Account
            </div>
            <div className="secondary-submenu-action-content">
              <p>Are you sure you want to delete your account? This cannot be undone.</p>
              <div className="confirmation-buttons">
                <button className="settings-action-button danger" onClick={handleConfirmDeleteUserClick}>
                  Yes, Delete My Account
                </button>
                <button className="settings-action-button" onClick={() => handleSubmenuClick(submenus.ACCOUNT)}>
                  No, Keep My Account
                </button>
              </div>
            </div>
          </div>
        )}

        {selectedSubmenu === submenus.DELETE_CHATS && (
          <div className="settings-submenu-actions">
            <div className="settings-submenu-action secondary">
              <FaArrowLeft className="settings-back-arrow" onClick={() => handleSubmenuClick(submenus.DATA_CONTROLS)} />
              Delete Chat History
            </div>
            <div className="secondary-submenu-action-content">
              {deleteAllChatsResult.success === null ? (
                <>
                  <p>Are you sure you want to delete your chat history? This cannot be undone.</p>
                  <div className="confirmation-buttons">
                    <button className="settings-action-button danger" onClick={handleConfirmDeleteChatsClick}>
                      Yes, Delete All Chats
                    </button>
                    <button
                      className="settings-action-button"
                      onClick={() => handleSubmenuClick(submenus.DATA_CONTROLS)}
                    >
                      No, Keep My Chats
                    </button>
                  </div>
                </>
              ) : (
                <p className={`submit-message ${deleteAllChatsResult.success ? "success" : "error"}`}>
                  {deleteAllChatsResult.message}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsMenu;
