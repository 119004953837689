import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCheck, FaCheckCircle } from "react-icons/fa";

const Section3 = () => {
  const navigate = useNavigate();
  const [subscriptionType, setSubscriptionType] = useState("annual");
  const handleToggle = (event) => {
    // If the checkbox is checked, it means the toggle is set to "annual"
    setSubscriptionType(subscriptionType === "annual" ? "monthly" : "annual");
  };

  return (
    <section className="section3">
      <header className="header-text-center pricing-header">
        <h1>Pricing</h1>
      </header>
      <div className="cancel-anytime">
        <FaCheck className="check-icon" />
        <p>Cancel Anytime</p>
      </div>
      <div className="price-selector-container">
        <p className="fancy-text">Save 50%</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 28 28"
          fill="none"
          style={{ position: "absolute", right: "-30px", top: "10px" }}
        >
          <path
            d="M26.1515 2.15135C24.9221 10.1438 18.3707 26.1027 2.00048 25.9994"
            stroke="#1465FA"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </svg>
        <div className="price-selector">
          <p>Monthly</p>
          <label className="switch">
            <input type="checkbox" checked={subscriptionType === "annual"} onChange={handleToggle} />
            <span className="slider round"></span>
          </label>
          <p>Annually</p>
        </div>
      </div>
      <div className="pricing-plans-container">
        <div id="monthly" className="pricing-plan">
          <div className="pricing-title">
            <div className="pricing-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="32" viewBox="0 0 19 38" fill="none">
                <path
                  d="M19 0C13.9609 -6.00908e-08 9.12816 2.00178 5.56497 5.56497C2.00178 9.12816 4.40533e-07 13.9609 0 19C-4.40533e-07 24.0391 2.00178 28.8718 5.56497 32.435C9.12816 35.9982 13.9609 38 19 38L19 19V0Z"
                  fill="#1863E5"
                />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="32" viewBox="0 0 19 38" fill="none">
                <path
                  d="M-1.90735e-06 38C5.03911 38 9.87184 35.9982 13.435 32.435C16.9982 28.8718 19 24.0391 19 19C19 13.9609 16.9982 9.12817 13.435 5.56498C9.87184 2.00178 5.03912 2.5942e-06 5.22941e-06 2.15366e-06L-2.46316e-07 19L-1.90735e-06 38Z"
                  fill="#E9FFFF"
                />
              </svg>
            </div>
            <h4 className="pricing-name">Basic Plan</h4>
          </div>
          <div className="pricing-subtitle">
            <p>For a Gentle Start On Your Wellness Journey</p>
          </div>
          <div className="price">
            <p>Free</p>
          </div>
          <div className="pricing-plan-features">
            <p>What's Included</p>
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Basic Chatbot</p>
            </div>
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Basic Emotional Insights</p>
            </div>
            <div>
              <FaCheckCircle className="unincluded feature-icon" />
              <p>Voice Integration</p>
            </div>
            <div>
              <FaCheckCircle className="unincluded feature-icon" />
              <p>Early Detection</p>
            </div>
          </div>
          <div className="pricing-plan-button">
            <Link to="/signup" style={{ textDecoration: "none" }}>
              <button>Sign Up</button>
            </Link>
          </div>
        </div>

        <div id="annual" className="pricing-plan">
          <div className="most-popular">
            <p>Most Popular</p>
          </div>
          <div className="pricing-title">
            <div className="pricing-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <rect width="16" height="32" fill="#1863E5" />
                <rect x="16" width="16" height="32" fill="#B8B1FF" />
                <rect x="16" y="16" width="16" height="16" fill="#E9FFFF" />
              </svg>
            </div>
            <h4 className="pricing-name">Premium Plan</h4>
          </div>
          <div className="pricing-subtitle">
            <p>Get Everything in the Basic Plan, Plus Additional Features</p>
          </div>
          <div className="price">
            {subscriptionType === "annual" && (
              <p>
                <s>$16</s>&nbsp;$8<span>&nbsp;/ month</span>
              </p>
            )}
            {subscriptionType === "monthly" && (
              <p>
                $16<span>&nbsp;/ month</span>
              </p>
            )}
          </div>
          <div className="pricing-plan-features">
            <p>What's Included</p>
            {subscriptionType === "annual" && (
              <div>
                <FaCheckCircle className="included feature-icon" />
                <p>14 Day Free Trial</p>
              </div>
            )}
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Personalized Chatbot</p>
            </div>
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Premium Emotional Insights</p>
            </div>
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Voice Integration</p>
            </div>
            <div>
              <FaCheckCircle className="included feature-icon" />
              <p>Advanced Early Detection</p>
            </div>
          </div>
          {/* TODO: Add navigation to stripe checkout page */}
          <div className="pricing-plan-button">
            <Link to="/login" style={{ textDecoration: "none" }}>
              {subscriptionType === "annual" && <button>Free Trial</button>}
              {subscriptionType === "monthly" && <button>Sign Up</button>}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section3;
