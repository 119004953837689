import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";
import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import store from "./redux/store";

const root = createRoot(document.getElementById("root"));

root.render(
  <CookiesProvider>
    <React.StrictMode>
      <GoogleOAuthProvider clientId="827977359761-fe39laisu4jecrorrgr0bc3lu3sa5m96.apps.googleusercontent.com">
        {/* <App /> */}
        <Provider store={store}>
          <App />
        </Provider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </CookiesProvider>
);
