import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../App.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Header />
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <div className="text-block">
          <h4>Blue Guardian PRIVACY POLICY Effective Date: August 01, 2023</h4>
          <p>
            Blue Guardian and its subsidiaries (collectively, "Blue Guardian", "we", "us", and "our") are committed to
            protecting your privacy and maintaining the confidentiality of your personal information. This Privacy
            Policy applies to our collection, use, storage, and disclosure of your personal information through our
            website, mobile applications, and any other services we provide (collectively, the "services"). We reserve
            the right to update this Privacy Policy at any time, and such modifications shall be effective immediately.
          </p>
        </div>
        <div className="text-block">
          <h4>CONSENT</h4>
          <p>
            By providing Blue Guardian with your personal information, you understand and agree to our collection, use,
            storage, disclosure, and retention of your information in accordance with this Privacy Policy or as
            otherwise permitted by law. You may withdraw your consent to our use of your personal information at any
            time, subject to legal or contractual restrictions and reasonable notice.
          </p>
        </div>
        <div className="text-block">
          <h4>INFORMATION WE COLLECT</h4>
          <p>
            We collect information in three categories: (1) information you provide to us; (2) information we collect
            when you use our services; and (3) information we collect from other sources.
          </p>
          <ol>
            <li>
              INFORMATION YOU PROVIDE TO US. We collect information you give us when you use our services, such as when
              you create an account, engage in therapy sessions, complete assessments, or surveys, contact our support
              team, and participate in our promotional activities. The information you provide may include your name,
              mailing address, email address, phone number, date of birth, gender, payment information, health history,
              and other personal or demographic information.
            </li>
            <li>
              INFORMATION WE COLLECT WHEN YOU USE OUR SERVICES. When you use our services, we may collect information
              about your usage, device and browser information, and location data to improve the services, ensure a
              secure environment, and offer you personalized content and advertising.
            </li>
            <li>
              INFORMATION WE COLLECT FROM OTHER SOURCES. We may also collect publicly available information or
              information you submit on platforms such as blogs, chat rooms, or social networks like Facebook,
              Instagram, Twitter, or LinkedIn.
            </li>
          </ol>
        </div>
        <div className="text-block">
          <h4>HOW WE USE THE INFORMATION WE COLLECT</h4>
          <p>We use the information we collect for various purposes, including:</p>
          <ul>
            <li>
              To provide and manage our services, process payments, and facilitate communication between you and our
              mental health professionals.
            </li>
            <li>To respond to your inquiries, support requests, or provide you with information about our services.</li>
            <li>
              To send you customized content, promotions, and special offers, including through email and social media
              platforms.
            </li>
            <li>To improve our services and develop new features based on your feedback and usage patterns.</li>
            <li>
              To maintain and improve the security and performance of our services, detect, and prevent fraud or
              security breaches.
            </li>
            <li>
              To comply with legal requirements, enforce our policies, or protect the rights, property, or safety of
              Blue Guardian, our users, or others.
            </li>
          </ul>
        </div>
        <div className="text-block">
          <h4>HOW WE SHARE THE INFORMATION WE COLLECT</h4>
          <p>We may share your information in the following circumstances:</p>
          <ul>
            <li>
              With our subsidiaries and affiliated companies for proper management, analysis, and decision making.
            </li>
            <li>
              With service providers that support our services, such as payment processors, hosting providers, or
              analytics providers, with the requirement that they protect your information and use it only for the
              purposes we specify.
            </li>
            <li>
              When required by law, to enforce our agreements or policies, or to protect the rights, property, or safety
              of Blue Guardian, our users, or others.
            </li>
            <li>
              With your consent or at your direction, such as when you choose to share information with a third party
              for their services.
            </li>
            <li>In anonymized or aggregated form, in a way that does not directly identify you.</li>
          </ul>
        </div>
        <div className="text-block">
          <h4>MANAGING YOUR PREFERENCES</h4>
          <p>
            You may opt out of receiving promotional communications by following the opt-out instructions in the
            messages we send, updating your preferences in your account settings, or contacting us at the email or
            postal address provided in the "Contact Us " section below. Please note that even if you opt out of
            promotional communications, we may still send you non-promotional messages, such as those regarding your
            account or purchases.
          </p>
        </div>
        <div className="text-block">
          <h4>ACCESSING, CORRECTING, OR DELETING YOUR INFORMATION</h4>
          <p>
            You have the right to access, correct, or delete your personal information held by us. To make a request,
            please contact us using the information provided in the "Contact Us" section below. We may require proof of
            your identity before fulfilling your request.
          </p>
        </div>
        <div className="text-block">
          <h4>HOW WE PROTECT YOUR INFORMATION</h4>
          <p>
            Blue Guardian takes appropriate measures to safeguard your personal information against unauthorized access,
            use, modification, destruction, or disclosure. We employ technical and organizational security measures such
            as encryption, firewalls, anti-virus software, and access controls. We also limit access to your personal
            information to employees, contractors, and agents who have a legitimate need to access it for the purposes
            outlined in this Privacy Policy.
          </p>
        </div>
        <div className="text-block">
          <h4>STORING AND PROCESSING YOUR INFORMATION</h4>
          <p>
            Your personal information may be stored and processed on servers owned by Blue Guardian or other companies
            in various locations, including the United States. We will take steps to protect your information in
            accordance with applicable data protection laws and this Privacy Policy, regardless of where it is
            processed.
          </p>
        </div>
        <div className="text-block">
          <h4>LINKS TO THIRD-PARTY WEBSITES</h4>
          <p>
            Our services may contain links to third-party websites or services. We do not control these websites and are
            not responsible for their privacy practices or content. We encourage you to review the privacy policies of
            any third-party websites you visit.
          </p>
        </div>
        <div className="text-block">
          <h4>CHANGES TO THIS PRIVACY POLICY</h4>
          <p>
            We may update this Privacy Policy from time to time. If we make significant changes, we will post the
            updated policy on this page and change the effective date at the top. We encourage you to review this
            Privacy Policy periodically to stay informed about our privacy practices.
          </p>
        </div>
        <div className="text-block">
          <h4>CONTACT US</h4>
          <p>
            If you have any questions or concerns about this Privacy Policy or our privacy practices, or if you wish to
            access, correct, or delete your personal information, you can contact our Privacy Officer in writing at Blue
            Guardian, 1572 Noah Bend, London, ON N6G 5S1, Canada, or by email at <span>contact@blueguardian.com</span>.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
