import React, { useState, useEffect, useRef } from "react";

// Subsection1
import macbookImg from "../images/macbook.png";
import logoImage from "../images/logo.png";
import { FaBars } from "react-icons/fa6";
import s1CardImg1 from "../images/self-esteem.png";
import s1CardImg2 from "../images/reduce-stress.png";
import s1CardImg3 from "../images/relationships.png";

// Subsection2
import { FaCirclePlay, FaCirclePause, FaCaretDown } from "react-icons/fa6";
import audio_EncAndPos from "../audio/encouragementAndPositivity.mp3";
import audio_nonDirApp from "../audio/nonDirectiveApproach.mp3";
import audio_ResAndInfShr from "../audio/resourceAndInformationSharing.mp3";

// Subsection3
import s3CardImg1 from "../images/levelsOfAnxiety.png";
import s3CardImg2 from "../images/futureSessionsRecommendation.png";
import s3CardImg3 from "../images/sourcesOfStress.png";

// Subsection4
import accountabilityImg1 from "../images/accountability1.png";
import accountabilityImg2 from "../images/accountability2.png";

const Section2 = () => {
  /*** SUBSECTION 1: Personal Customization ***/
  const ss1CardsData1 = [
    {
      img: s1CardImg1,
      h: "Build Self-Esteem",
      desc: "Build Acceptance, Resilience, Self-Reflection",
    },
    {
      img: s1CardImg2,
      h: "Reduce Stress",
      desc: "Reduce Relationship Strain, Health Issues, Work-Related Stress",
    },
    {
      img: s1CardImg3,
      h: "Interpersonal Relationships",
      desc: "Improve Communication, Social Skills, Emotion Management",
    },
  ];
  // Data for subsection 2 cards after being clicked.
  const ss1CardsData2 = [
    {
      h: "Encouragement & Positivity",
      desc: "You're not alone. Let's explore ways to work through this.",
    },
    {
      h: "Non-Directive Approach",
      desc: "I'm here to listen and support you. How would you like to approach this?",
    },
    {
      h: "Resource & Information Sharing",
      desc: "I can provide coping strategies or information on finding support.",
    },
  ];

  /* Handles Card State in Subsection 1 */
  const [toggleCards, setToggleCards] = useState(true);
  const handleToggleCards = () => {
    setToggleCards(!toggleCards);
  };

  /* Pseudo Navbar is the fake mobile navbar that appears in the Macbook
     image in subsection 2.
     This handles the state for the mobile version of the fake navbar,
     which is not currently being used, but is left here if a fake iPhone screen
     is added in place of the Macbook in the future.
  */
  const [showPseudoNavbar, setShowPseudoNavbar] = useState(false);
  const handleShowPseudoNavbar = () => {
    setShowPseudoNavbar(!showPseudoNavbar);
  };

  /*** SUBSECTION 2: Voice Integrations ***/
  const [isPlaying, setIsPlaying] = useState(false);
  const barsRef = useRef([]);
  const animationTimeoutRef = useRef(null);
  const audioRef = useRef(null);
  const ss2Audios = [
    {
      name: "Encouragement & Positivity",
      audio: audio_EncAndPos,
    },
    {
      name: "Non-Directive Approach",
      audio: audio_nonDirApp,
    },
    {
      name: "Resource and Information Sharing",
      audio: audio_ResAndInfShr,
    },
  ];
  const [selectedAudio, setSelectedAudio] = useState(ss2Audios[0].audio);

  // Function to handle audio selection
  const handleAudioSelection = (audio) => {
    setSelectedAudio(audio);
    if (isPlaying) {
      // If an audio is playing, switch to the new audio immediately
      audioRef.current.pause();
      audioRef.current.src = audio;
      audioRef.current.play().catch((error) => {
        console.error("Playback was prevented: ", error);
        setIsPlaying(false);
      });
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause(); // Pause the audio
      audioRef.current.currentTime = 0; // Reset the audio to the start
    } else {
      audioRef.current.src = selectedAudio;
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            // Playback started
          })
          .catch((error) => {
            // Handle error for when playback was prevented
            console.error("Playback was prevented: ", error);
            setIsPlaying(false);
          });
      }
    }
    setIsPlaying(!isPlaying);
  };

  const animateBars = () => {
    const delay = 135; // Delay for the soundwave animation (frequency that bars change height)
    barsRef.current.forEach((bar, index) => {
      let scale = Math.random() * 2; // Random scale between 0 and 2
      const setRandomBarToZero = Math.random() % 10;
      scale = setRandomBarToZero <= 0.3 ? 0 : scale; // Sets a random bar to 0 height ~30% of the time; improves look of animation
      if (bar) bar.style.transform = `scaleY(${scale})`;
    });

    if (isPlaying) {
      animationTimeoutRef.current = setTimeout(() => {
        requestAnimationFrame(animateBars);
      }, delay);
    }
  };

  useEffect(() => {
    // Function to handle audio ending
    const handleAudioEnd = () => {
      setIsPlaying(false);
    };

    const currentAudio = audioRef.current;
    currentAudio.addEventListener("ended", handleAudioEnd);

    // Play/pause management
    if (isPlaying) {
      requestAnimationFrame(animateBars);
    } else {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
    }

    // Cleanup function
    return () => {
      currentAudio.removeEventListener("ended", handleAudioEnd);
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
      barsRef.current.forEach((bar) => {
        if (bar) bar.style.transform = "scaleY(1)"; // Reset the bar scale when stopping
      });
    };
  }, [isPlaying]);

  /*** SUBSECTION 3: Emotional Insights ***/
  const ss3CardsData = [
    {
      img: s3CardImg1,
      h: "Levels of Anxiety",
    },
    {
      img: s3CardImg2,
      h: "Future Sessions Recommendation",
    },
    {
      img: s3CardImg3,
      h: "Sources of Stress",
    },
  ];

  /* 
     Handles subsection3 card content (ss3CardsData) cycling and fading in and out
     Concept Reference: https://bionicjulia.com/blog/creating-react-component-fades-changing-words
  */
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    // Only run the animation if this subsection is in the viewport
    const isSS3InViewport = () => {
      const subsection3 = document.querySelector(".features-subsection-container.subsection3");
      const subsection3Rect = subsection3.getBoundingClientRect();
      return subsection3Rect.top < window.innerHeight && subsection3Rect.bottom >= 0;
    };

    const cardContentSwapTimeout = setInterval(() => {
      if (isSS3InViewport()) {
        // Fade out effect for each card content
        const cardContents = document.querySelectorAll(".subsection3 .card-container .card .card-content");

        // Add fade-out effect for each card content, remove fade-in
        cardContents.forEach((content) => {
          content.classList.remove("fade-in");
          content.classList.add("fade-out");
        });

        // Wait for fade out to complete
        setTimeout(() => {
          // Update current index for cycling
          setCurrentIndex((prevIndex) => (prevIndex + 1) % ss3CardsData.length);

          // Cycle the card content
          cardContents.forEach((content, index) => {
            const cardData = ss3CardsData[(currentIndex + index) % ss3CardsData.length];
            content.innerHTML = `
              <div>
                <h4>${cardData.h}</h4>
              </div>
              <img src=${cardData.img} alt="Streak" />
            `;
          });

          // Add fade-in effect for each card content, remove fade-out
          cardContents.forEach((content) => {
            content.classList.remove("fade-out");
            content.classList.add("fade-in");
          });
        }, 400); // Time to wait for fadeout transition (same as) css class .fade-out transition time)
      }
    }, 5000); // Time that it takes for card content to cycle

    // Cleanup interval on component unmount
    return () => clearInterval(cardContentSwapTimeout);
  }, [currentIndex, ss3CardsData]);

  return (
    <section className="section2">
      <div className="header-text-center">
        <h1>
          <span>How It Works</span>
        </h1>
      </div>
      {/* Personalization Features */}
      <div className="features-subsection-container subsection1">
        <div className="subsection-headline-container">
          <h1>Customizable Advanced Chatbot</h1>
          <p>
            Tailor your experience by adapting the chatbot to align with your communication style and individual mental
            health objectives.
          </p>
        </div>
        <div className="subsection-content-container">
          <img src={macbookImg} alt="Computer Screen" className="macbook-screen"></img>
          <div className="subsection-content">
            {/* Fake navbar in macbook screen */}
            <div className="pseudo-navbar-container">
              <div className="pseudo-navbar-logo">
                <img src={logoImage} alt="Logo"></img>
                <h3>BLUE GUARDIAN</h3>
              </div>
              <div className="pseudo-mobile-menu-icon">
                <FaBars />
              </div>
              <div className="pseudo-navbar-elements">
                <div className="pseudo-navbar-links">
                  <p>Product</p>
                  <p>Features</p>
                  <p>Pricing</p>
                  <p>Company</p>
                </div>
                <div className="pseudo-navbar-signup-login">
                  <p>Login</p>
                  <p className="pseudo-signup">Sign Up</p>
                </div>
              </div>
            </div>
            <div className="macbook-headline">
              {toggleCards && <h2>Select Your Goal</h2>}
              {!toggleCards && <h2>Select Your Communication Style</h2>}
              <p>
                A study by Dominican University found that people who set goals are <b>43 percent</b> more likely to
                achieve them.
              </p>
            </div>
            <div className="card-container">
              {toggleCards &&
                ss1CardsData1.map((item, index) => (
                  <div className="card1" key={index} onClick={handleToggleCards}>
                    <div className="card1-img-container">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="163"
                        height="163"
                        viewBox="0 0 163 163"
                        fill="none"
                      >
                        <circle cx="81.7009" cy="81.2254" r="75" fill="#C7D9F3" fillOpacity="0.3" />
                      </svg>
                      <img src={item.img} alt="Friends"></img>
                    </div>
                    <div>
                      <h3>{item.h}</h3>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                ))}
              {!toggleCards &&
                ss1CardsData2.map((item, index) => (
                  <div className="card2" key={index} onClick={handleToggleCards}>
                    <div className="speech-bubble">
                      <p>"{item.desc}"</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="180"
                        height="180"
                        viewBox="0 0 136 136"
                        fill="white"
                      >
                        <path
                          d="M38.6469 129.109C32.9099 132.471 21.9812 137.118 10.8393 132.4L2.84838 129.016L17.1794 112.98C8.26268 102.968 2.51318 90.5462 0.65501 77.2787C-1.20316 64.0113 0.913098 50.4916 6.73723 38.4224C12.5614 26.3532 21.8329 16.2741 33.3843 9.45455C44.9357 2.63499 58.2503 -0.620093 71.6513 0.0992297C85.0523 0.818553 97.9404 5.48012 108.693 13.4969C119.445 21.5136 127.58 32.527 132.074 45.1501C136.568 57.7731 137.22 71.4414 133.947 84.4334C130.674 97.4255 123.624 109.16 113.683 118.16C103.699 127.253 91.2175 133.156 77.8447 135.107C64.472 137.058 50.8201 134.969 38.6469 129.109ZM117.258 23.2855C111.392 16.8259 104.308 11.5831 96.4121 7.85656C88.516 4.12999 79.9618 1.99268 71.2381 1.56666C62.5143 1.14065 53.792 2.4343 45.5692 5.37373C37.3464 8.31315 29.7842 12.8408 23.3146 18.698C16.845 24.5552 11.5946 31.6272 7.86339 39.5103C4.13218 47.3933 1.99323 55.9328 1.56867 64.6411C1.1441 73.3494 2.44225 82.0559 5.38898 90.2634C8.33572 98.4708 12.8733 106.018 18.7426 112.475L19.1961 112.974L5.36292 128.454L11.4254 131.021C22.1862 135.577 32.8346 130.844 38.2243 127.617L38.5727 127.408L38.9382 127.586C52.8317 134.325 68.6419 136.025 83.6546 132.395C98.6672 128.765 111.946 120.032 121.21 107.694C130.475 95.3559 135.148 80.1833 134.426 64.7808C133.705 49.3784 127.635 34.707 117.258 23.2855Z"
                          fill="#3081EE"
                        />
                      </svg>
                    </div>
                    <h3>{item.h}</h3>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* Voice Integration */}
      <div className="features-subsection-container subsection2">
        <div className="subsection-headline-container">
          <h1>Voice Integration</h1>
          <p>
            Share your thoughts aloud, and let our chatbot respond with a soothing, natural voice filled with empathy
            and understanding. This feature adds a human touch to your support system, making your interactions feel
            comforting and authentic as you navigate your wellness journey.
          </p>
        </div>
        <div className="subsection-content-container">
          <div className="audio-select-container">
            <p>Choose a conversational style to hear:</p>
            <div className="audio-select">
              <FaCaretDown className="audio-select-icon" />
              <select onChange={(e) => handleAudioSelection(e.target.value)} value={selectedAudio}>
                {ss2Audios.map((audio, index) => (
                  <option key={index} value={audio.audio}>
                    {audio.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="soundwave-container">
            {isPlaying ? (
              <FaCirclePause className="play-icon" onClick={togglePlay} />
            ) : (
              <FaCirclePlay className="play-icon" onClick={togglePlay} />
            )}
            <div className={`sound-wave ${isPlaying ? "animate" : ""}`}>
              {/* Generates the bars in the sound wave */}
              {[...Array(80)].map((_, i) => (
                <div key={i} ref={(el) => (barsRef.current[i] = el)} className="wave"></div>
              ))}
            </div>{" "}
            <audio ref={audioRef} src={audio_EncAndPos} preload="auto" type="audio/mp3"></audio>
          </div>
        </div>
      </div>

      {/* Emotional Insights */}
      <div className="features-subsection-container subsection3">
        <div className="subsection-headline-container">
          <h1>Enhanced Emotional Insights</h1>
          <p>
            Enhanced Emotional Insights spots early signs of mental health issues, gives recommendations for future
            sessions, improves chatbot memory and responses, and sends weekly emails for ongoing support in your mental
            health journey.
          </p>
        </div>
        <div className="subsection-content-container">
          <div className="card-container">
            {ss3CardsData.map((cardData, index) => (
              <div className="card" key={index}>
                <div className="card-content fade-in">
                  <div>
                    <h4>{cardData.h}</h4>
                  </div>
                  <img src={cardData.img} alt="Streak" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Accountability Buddies */}
      <div className="features-subsection-container subsection4">
        <div className="subsection-headline-container">
          <h1>Accountability Buddies</h1>
          <p>
            Create your own circle of support! Connect with up to five trusted friends to share successes, give each
            other a boost, and celebrate the steps you take on your wellness journey. It's like having your personal
            cheer squad - because supporting your well-being is better together!
          </p>
        </div>
        <div className="subsection-content-container">
          <div className="content1">
            <img src={accountabilityImg1} alt="Friends"></img>
          </div>
          <div className="content2">
            <div className="card">
              <img src={accountabilityImg2} alt="Friends"></img>
              <div>
                <h2>
                  You are in <span>1st</span> place!
                </h2>
                <p>Congrats! You're currently leading the way in your mental health journey. Keep up the good work!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
