import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useNavigate, Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AuthService from "../Services/AuthService";
// import { ReCaptchaScore, ReCaptchaCheckbox, ReCaptchaActions, ReCaptchaResponses } from "../Services/ReCaptcha.js";
import axios from "axios";
import Company from "./Company.js";
import { useCookies } from "react-cookie";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../backend/firebaseConfig";
import * as firebaseAuth from "firebase/auth";
import "../App.css";
import "./LoginPage.css";
import Popup from "./Popup.js";

const app = initializeApp(firebaseConfig);
const auth = firebaseAuth.getAuth(app);

function SignOut({ onLogin }) {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  const [signupEnabled, setSignupEnabled] = useState(true);
  // const reCaptchaScore = ReCaptchaScore(ReCaptchaActions.SIGNUP);
  // const [reCaptchaScoreRiskLevel, setReCaptchaScoreRiskLevel] = useState(ReCaptchaResponses.UNKNOWN);
  // const [showReCaptchaCheckbox, setShowReCaptchaCheckbox] = useState(false);
  // const [reCaptchaCheckboxStatus, setReCaptchaCheckboxStatus] = useState(ReCaptchaResponses.UNKNOWN);
  // const handleVerifyReCaptchaCheckbox = (result) => {
  //   setReCaptchaCheckboxStatus(result);
  //   setSignupEnabled(true);
  // };
  // const handleCheckboxReCaptchaExpire = () => {
  //   setReCaptchaCheckboxStatus(ReCaptchaResponses.UNKNOWN);
  //   setSignupEnabled(false);
  // };

  const navigate = useNavigate();
  const authService = AuthService();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkEmailFormat = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regular expression for email format
    emailRegex.test(e) ? setEmailValid(true) : setEmailValid(false);
  };

  const parseName = () => {
    const trimmedName = name.trim();
    const firstSpaceIndex = trimmedName.indexOf(" ");
    let firstName, lastName;
    if (firstSpaceIndex === -1) {
      firstName = trimmedName;
      lastName = "";
    } else {
      firstName = trimmedName.substring(0, firstSpaceIndex);
      lastName = trimmedName.substring(firstSpaceIndex + 1);
    }
    return { firstName, lastName };
  };

  const signupUser = async () => {
    try {
      const { firstName, lastName } = parseName();
      const registrationResult = await authService.registerUser({
        email,
        firstName: firstName,
        lastName: lastName,
        password,
        provider: "Email",
        googleId: null,
      });
      if (registrationResult.success) {
        setShowVerifyEmailPopup(true);
      } else {
        setErrorMessage(registrationResult.message);
      }
    } catch (error) {
      setErrorMessage("Sorry, an error occurred during registration. Please try again.");
      console.error("Error during registration:", error);
    }
  };

  const handleGetStarted = async (e) => {
    e.preventDefault();
    setSignupEnabled(false);
    if (!name || !email || !password || !emailValid || passwordError) {
      alert("Please fill in name, email, and password.");
      return;
    }

    signupUser();
    setSignupEnabled(true);
  };

  /** Uses recaptcha on the sign up button */
  // const handleGetStarted = async (e) => {
  //   e.preventDefault();

  //   // Attempt to register

  //   if (!name || !email || !password || !emailValid || passwordError) {
  //     alert("Please fill in name, email, and password.");
  //     return;
  //   }

  //   // if first create account click, or first create account attempt failed due to non-recaptcha reasons, so run score and signup if success and show checkbox if fail
  //   if (reCaptchaScoreRiskLevel === ReCaptchaResponses.UNKNOWN) {
  //     // is first signup attempt, so run ReCaptchaScore.
  //     const result = await reCaptchaScore.executeAndVerify();
  //     setReCaptchaScoreRiskLevel(result);
  //     if (result === ReCaptchaResponses.LOW_RISK) {
  //       signupUser();
  //     } else if (result === ReCaptchaResponses.HIGH_RISK || result === ReCaptchaResponses.INVALID) {
  //       // They are deemed high risk, or sent an invalid token/action, so show the checkbox for additional verification
  //       setShowReCaptchaCheckbox(true);
  //       setSignupEnabled(false);
  //       return;
  //     } else {
  //       // Something went wrong with verification, show an error message and return
  //       console.error("Recaptcha score failed", result);
  //       return;
  //     }
  //   }

  //   // Runs on subsequent login attempts if score failed
  //   if (showReCaptchaCheckbox) {
  //     if (reCaptchaCheckboxStatus === ReCaptchaResponses.UNKNOWN) {
  //       alert("Please complete the checkbox recaptcha to signup.");
  //     }

  //     if (reCaptchaCheckboxStatus === ReCaptchaResponses.LOW_RISK && signupEnabled) {
  //       signupUser();
  //     } else {
  //       // Add different logic here for different recaptchaCheckboxStatus results (ie. deny high risk or invalid/token action, etc)
  //       // For now we basically allow any user with a valid email and password who solved the checkbox recaptcha
  //       signupUser();
  //     }
  //   }
  // };

  useEffect(() => {
    if (user) {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
            Accept: "application/json",
          },
        })
        .then((res) => {
          setProfile(res.data);
          navigate("/dashboard");
        })
        .catch((err) => console.log(err));
    }
  }, [user, navigate]);

  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const login = async () => {
    try {
      const persistent = await firebaseAuth.setPersistence(auth, firebaseAuth.browserLocalPersistence);
      const provider = new firebaseAuth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      const userData = await firebaseAuth.signInWithPopup(auth, provider);
      const userDetails = {
        uid: userData.user?.uid,
        email: userData.user?.email,
        firstName: userData.user?.displayName?.split(" ").slice(0, -1).join(" "),
        lastName: userData.user?.displayName?.split(" ").slice(-1).join(" "),
        provider: "Google",
        googleId: userData.user?.providerData[0].uid,
        token: userData.user?.accessToken,
      };
      const signedUp = await authService.googleSignup(userDetails);
      if (signedUp) {
        sessionStorage.setItem("isLoggedIn", "true");
        navigate("/dashboard");
      } else {
        setErrorMessage("Sorry, an error occurred during sign up. Please try again.");
        removeCookie("token");
        const currentUser = auth.currentUser;
        await firebaseAuth.unlink(currentUser, "google.com");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    // Password validation logic
    const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/.test(newPassword);

    if (!isValidPassword) {
      setPasswordError(
        "Password must have at least 8 characters, one uppercase letter, one number, and one special symbol."
      );
    } else {
      setPasswordError(null);
    }
  };

  return (
    <section className="authorization-page-container">
      <div className="authorization-page-content">
        <div className="auth-page-company-logo">
          <Company />
        </div>
        <div className="auth-page-form-content">
          <h1>Create an account</h1>
          <form onSubmit={handleGetStarted}>
            <div className="input-container">
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder=" "
                required
              />
              <label className="input-label" htmlFor="name">
                Name
              </label>
            </div>
            <div className="input-container">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  checkEmailFormat(e.target.value);
                  setErrorMessage("");
                }}
                placeholder=" "
                required
              />
              <label className="input-label" htmlFor="email">
                Email address
              </label>
            </div>
            <div className="input-container">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                placeholder=" "
                required
              />
              <label className="input-label" htmlFor="password">
                Password
              </label>
              <span className="eye-toggle-icon" onClick={togglePasswordVisibility}>
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
            {passwordError && password.trim() !== "" && (
              <div className="input-error-message">
                <p>{passwordError}</p>
              </div>
            )}
            {/* {showReCaptchaCheckbox && (
              <ReCaptchaCheckbox
                reCaptchaAction={ReCaptchaActions.SIGNUP}
                onVerifyCheckbox={handleVerifyReCaptchaCheckbox}
                onCheckboxExpire={handleCheckboxReCaptchaExpire}
              />
            )} */}
            {errorMessage && <p className="signup-error-message">{errorMessage}</p>}
            <button
              type="submit"
              disabled={!signupEnabled}
              className={`action-button ${signupEnabled ? "" : "disabled"}`}
            >
              Create Account
            </button>
          </form>
          <div className="auth-cta-text-container">
            <p>Already have an account?</p>
            <Link to="/login">Login</Link>
          </div>
          <div className="or-line">
            <div className="line"></div>
            <p>OR</p>
            <div className="line"></div>
          </div>
          <button className="google-button" onClick={() => login()}>
            {/* prettier-ignore */}
            <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/><path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/><path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/><path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/><path d="M1 1h22v22H1z" fill="none"/>
          </svg>
            <p>Continue with Google</p>
          </button>
        </div>
        {/* <div className="recaptcha-statement">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
        </div> */}
        <div className="auth-cta-text-container t-and-c">
          <Link to="/privacy-policy">Privacy Policy</Link> <p>|</p>
          <Link to="/terms-and-conditions">Terms & Conditions</Link>
        </div>
      </div>

      {showVerifyEmailPopup && (
        <Popup isNightMode={false} onClosePopup={() => setShowVerifyEmailPopup(false)}>
          <div className="small-popup-container" style={{ textAlign: "center" }}>
            <div className="verify-email-popup">
              <h3>Thanks for signing up!</h3>
              <p>Please check your inbox to verify your email before logging in.</p>
              <div className="auth-cta-text-container">
                <p>Go to</p>
                <Link to="/login" onClick={() => setShowVerifyEmailPopup(false)}>
                  Login Page
                </Link>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </section>
  );
}

export default SignOut;
