import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import logoImage from "../images/logo.png";

const Company = () => {
  const linkStyle = {
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
  };

  return (
    <div className="company-button">
      <Link to="/" style={linkStyle}>
        <div className="logo-name" style={{ display: "flex", alignItems: "center" }}>
          <img src={logoImage} alt="Logo"></img>
          <h3 style={{ textShadow: "none", fontFamily: "Inter, sans-serif" }}>BLUE GUARDIAN</h3>
        </div>
      </Link>
    </div>
  );
};

export default Company;
