import React, { useEffect } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ChatApi = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  useEffect(() => {}, [cookies]);

  const createNewChat = async ({ chatGoal, chatStyle }) => {
    try {
      const token = cookies.token;
      const response = await axios.put(
        `${BASE_URL}/api/bot/new-chat/${chatGoal}/${chatStyle}`,
        {},
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return { chatId: response.data.data._id, timestamp: response.data.data.startTime };
    } catch (error) {
      console.error("Error creating new chat:", error.message);
      throw error;
    }
  };

  const getAllChats = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/bot/get-all-chats`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error getting all chats:", error.message);
      throw error;
    }
  };

  const getMessagesFromChat = async (chatID) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/bot/chat/${chatID}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error getting messages for chat ${chatID}:`, error.message);
      throw error;
    }
  };

  const generateChat = async (chatID, messageData) => {
    try {
      const response = await axios.post(`${BASE_URL}/api/bot/chat/${chatID}`, messageData, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error generating chat for ${chatID}:`, error.message);
      throw error;
    }
  };

  const deleteChat = async (chatID) => {
    try {
      const response = await axios.delete(`${BASE_URL}/api/bot/${chatID}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error(`Error deleting chat ${chatID}:`, error.message);
      throw error;
    }
  };

  const setChatTitle = async (chatID, userText) => {
    try {
      const payload = {
        text: userText,
      };
      const response = await axios.post(`${BASE_URL}/api/bot/title/${chatID}`, payload, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error setting chat title: ", error);
      throw error;
    }
  };

  const getChatTitle = async (chatID) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/bot/title/${chatID}`, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error getting chat title: ", error);
      throw error;
    }
  };

  return {
    createNewChat,
    getAllChats,
    getMessagesFromChat,
    deleteChat,
    generateChat,
    setChatTitle,
    getChatTitle,
  };
};

export default ChatApi;
