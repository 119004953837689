import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

const TopButton = () => {
  const [showTopButton, setShowTopButton] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    // Check if the user has scrolled past a certain point
    const scrollThreshold = 500;
    const shouldShowButton = window.scrollY > scrollThreshold;

    setShowTopButton(shouldShowButton);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showTopButton && (
        <FaAngleUp className="top-button" onClick={() => scrollToTop()}>
          Top
        </FaAngleUp>
      )}
    </div>
  );
};

export default TopButton;
