import React from "react";
import Carousel from "./Carousel";
import kyleImg from "../images/kyle.png";
import shubhamImg from "../images/shubham.png";
import emmanuelImg from "../images/emmanuel.png";
import linkedinLogo from "../images/linkedinlogo.png";

const Section4 = () => {
  const teamMemberData = [
    {
      img: emmanuelImg,
      name: "Emmanuel",
      title: "CEO",
      description:
        "Emmanuel is a visionary leader with a passion for innovation and excellence. With a strong background in business strategy and management, he has successfully steered the company towards its goals, ensuring a focus on customer satisfaction and sustainable growth. His leadership style fosters a culture of collaboration and creativity, making him a respected figure in the industry.",
      linkedin: "emmanuel-akindele-97b00b104",
    },
    {
      img: kyleImg,
      name: "Kyle",
      title: "CTO",
      description:
        "Kyle is a seasoned technologist with a knack for developing cutting-edge solutions. As the CTO, he oversees the technical direction of the company, ensuring that the technology strategy aligns with the overall business goals. His expertise in software development and system architecture has been instrumental in building robust and scalable products that meet the needs of clients.",
      linkedin: "kylelacroix",
    },
    {
      img: shubhamImg,
      name: "Shubham",
      title: "Founding Engineer",
      description:
        "Shubham is a founding engineer with a deep understanding of the technical challenges faced by startups. He has played a pivotal role in shaping the company's technology stack and has been a driving force behind the development of innovative features. His commitment to excellence and continuous improvement has helped the company stay ahead in a competitive market.",
      linkedin: "gargshub",
    },
  ];

  const carouselItems = teamMemberData.map((teamMember, index) => (
    <div key={index} className="team-member-card">
      <div className="inactive-overlay"></div>
      <div className="team-member-img-container">
        <img src={teamMember.img} alt="Team Member" className="team-member-img" />
      </div>
      <div className="team-member-info">
        <div>
          <h2 className="team-member-name">Hi, I'm {teamMember.name}</h2>
          <h3 className="team-member-title">I work as the {teamMember.title}</h3>
        </div>
        <p className="team-member-description">{teamMember.description}</p>
        <a href={`https://www.linkedin.com/in/${teamMember.linkedin}`} target="_blank">
          <img className="team-member-social-icon" src={linkedinLogo} />
        </a>
      </div>
    </div>
  ));

  return (
    <section className="team-section">
      <header className="team-header">
        <h1>
          The Amazing <span>Team</span> Behind <br /> <span>Blue Guardian</span>
        </h1>
      </header>
      <div className="team-container">
        <Carousel items={carouselItems} componentID="team" scrollSnapType="center" />
      </div>
    </section>
  );
};

export default Section4;
