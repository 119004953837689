// import React, { useState, useEffect } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import axios from "axios";
// import Main from "./components/Main";
// import LoginPage from "./LoginPage";
// import Dashboard from "./components/Dashboard";
// import SignOut from "./components/SignOut";
// import StripePricingTable from "./components/StripePricingTable";

// function App() {
//   const [user, setUser] = useState(null);
//   const [profile, setProfile] = useState(null);

//   useEffect(() => {
//     if (user) {
//       axios
//         .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
//           headers: {
//             Authorization: `Bearer ${user.access_token}`,
//             Accept: "application/json",
//           },
//         })
//         .then((res) => {
//           setProfile(res.data);
//         })
//         .catch((err) => console.log(err));
//     }
//   }, [user]);

//   const login = (codeResponse) => {
//     setUser(codeResponse);
//   };

//   const logOut = () => {
//     setUser(null);
//     setProfile(null);
//   };

//   return (
//     <Router>
//       <div>
//         <Routes>
//           <Route path="/" element={<Main />} />
//           {/* <Route path="/dashboard/*" element={<PrivateRoute profile={profile} logOut={logOut} />} /> */}
//           <Route path="/dashboard" element={<Dashboard profile={profile} logOut={logOut} />} />
//           <Route path="/login" element={<LoginPage onLogin={login} />} />
//           <Route path="/payment" element={<StripePricingTable />} />
//           <Route path="/signup" element={<SignOut onLogin={SignOut} />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import App2 from "./App2";

function App() {
  return (
    <Router>
      <App2 />
    </Router>
  );
}

export default App;
