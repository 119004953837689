import React from "react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import logoImg from "../images/logo.png";

const SignUpCTASection = () => {
  return (
    <section className="signup-cta-section">
      <div className="signup-cta-content">
        <div className="text-content">
          <header>
            <h1>
              <span style={{ color: "rgba(255, 255, 255, 1)" }}>Empathy, </span>
              <span style={{ color: "rgba(172, 215, 255, 1)" }}>Each Minute, </span>
              <span style={{ color: "rgba(247, 179, 47, 1)" }}>Everyday.</span>
            </h1>
          </header>
          <div className="signup-cta-buttons">
            <Link to="/signup">
              <button className="plain-signup-button">
                <p>
                  <b>Sign up</b>, it's free
                </p>
              </button>
            </Link>
            <Link to="/signup">
              <button className="google-signup-button">
                <FcGoogle style={{ width: "auto", height: "20px" }} />
                <p>Sign up with Google</p>
              </button>
            </Link>
          </div>
          <p className="t-and-c">
            By signing up, you agree to the&nbsp;
            <Link to="/">Terms & Conditions</Link>
            &nbsp;and&nbsp;
            <Link to="/">Privacy Policy</Link>
          </p>
        </div>
        {/* <div className="signup-cta-media">
          <img src={logoImg} style={{ width: "auto", height: "128px" }} />
        </div> */}
      </div>
    </section>
  );
};

export default SignUpCTASection;
