import React from "react";
import { FaXmark } from "react-icons/fa6";
import "./Dashboard.css";

/**
 * Wrapper for content that goes in a popup.
 * The width and height of the parent element of the child determines the width of the popup.
 * @param {boolean} isNightMode - Whether night mode is on.
 * @param {function} onClosePopup - Function to close the popup.
 * @param {JSX} children - Content to display in the popup.
 */
const Popup = ({ isNightMode, onClosePopup, children }) => {
  return (
    <div className="popup-container">
      <div className={`popup-content ${isNightMode ? "popup-night-mode" : ""}`}>
        <div className="popup-close-icon-container">
          <FaXmark className="popup-close-icon" onClick={onClosePopup} />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Popup;
