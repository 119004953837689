import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate, Navigate } from "react-router-dom";
import AuthService from "./Services/AuthService";
import axios from "axios";
import Main from "./components/Main";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import EmailVerified from "./components/EmailVerified";
import Blog from "./components/Blog";
import BlogTemplate from "./templates/BlogTemplate";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import TermsAndConditions from "./components/TermsAndConditions";
// import ReactGA from "react-ga";

// const TRACKING_ID = "G-NJXERBP1CZ";
// const TRACKING_ID = "G-G019FQPM2T";
// ReactGA.initialize(TRACKING_ID);

function App2() {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoggedInAuth, setLoggedInAuth] = useState(false);
  const { isLoggedIn } = AuthService();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  //TODO: Remove login check on main page
  useEffect(() => {
    const checkLoggedIn = async () => {
      const isAuthenticated = await isLoggedIn();
      setLoggedInAuth(isAuthenticated.status);

      // If not authenticated and trying to access dashboard, navigate to login
      if (!isAuthenticated.status && window.location.pathname === "/dashboard") {
        navigate("/login", { replace: true });
      }
    };

    checkLoggedIn();
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    if (user) {
      setProfile(user);
    }
  }, [user]);

  const login = (codeResponse) => {
    setUser(codeResponse);
  };

  const logOut = () => {
    setUser(null);
    setProfile(null);
  };

  // Scrolls to the top of the screen when navigating to a new route
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/dashboard" element={isLoggedInAuth ? <Dashboard profile={profile} logOut={logOut} /> : null} />
        {/* <Route path="/dashboard" element={<Dashboard profile={profile} logOut={logOut} />} /> */}
        <Route path="/login" element={<LoginPage onLogin={login} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/signup" element={<SignUp onLogin={SignUp} />} />
        <Route path="/email-verified" element={<EmailVerified />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogTemplate />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
    </div>
  );
}

export default App2;
