import React, { useState, useEffect } from "react";
import "../App.css";

const SocialProofPopup = () => {
  const names = [
    "Aarav",
    "Sofia",
    "Elijah",
    "Zara",
    "Mateo",
    "Aisha",
    "Kai",
    "Leilani",
    "Ravi",
    "Amara",
    "Emily",
    "Michael",
    "Emmanuel",
    "Olivia",
    "Daniel",
    "Sophia",
    "Emmalee",
    "Matthew",
    "Ava",
    "Ryan",
    "Jackson",
    "Liam",
    "Mia",
    "Lucas",
    "Grace",
    "Isaac",
    "Aiden",
    "Ella",
    "Aiden",
    "Mila",
  ];

  const getRandomIndex = (max) => Math.floor(Math.random() * max); // Sets a random initial first name
  const [currentNameIndex, setCurrentNameIndex] = useState(getRandomIndex(names.length));
  const [fadeIn, setFadeIn] = useState(true); // Handles the fading and fading out

  // Handles selecting names for the popup
  useEffect(() => {
    const interval = setInterval(() => {
      setFadeIn(false); // Start fade out
      setTimeout(() => {
        setCurrentNameIndex((prevIndex) => (prevIndex + 1) % names.length); // Pick next name
        setFadeIn(true); // Start fade in with the new name
      }, 5000); // 5 seconds delay between each 
    }, 20000); // Show each for 20 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className={`social-proof-popup ${fadeIn ? "fade-in" : "fade-out"}`}>
      <div className="social-proof-content">
        <p>
          {names[currentNameIndex]} <span>Subscribed</span> to our <span>Premium Plan</span> 🎉
        </p>
      </div>
    </div>
  );
};

export default SocialProofPopup;
