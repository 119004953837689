import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
// import "./UploadForm";
// import "./UploadForm.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "groove",
    overflow: "auto",
    maxWidth: "85%",
    maxHeight: "96vh",
  },
};

const therapy_info_data = {
  SpeechAndThough:
    "Changes in Speech or Thought Process: Refers to any noticeable alterations in the individual's style or pace of speech, or their thought patterns, possibly indicating cognitive issues, emotional distress, or mental disorders.",
  InsightsorRealizations:
    "Insights or Realizations: This term represents any personal insights or realizations that the individual has discovered during the session. These could be related to their thoughts, behaviors, or emotional patterns.",
  SentimentsAndEmotions:
    "Key Sentiments and Emotions: Signifies the main emotions or feelings that the individual has communicated or demonstrated during the session, providing a glimpse into their emotional state.",
  AnxietyAndDistress:
    "Levels of Anxiety or Distress: Pertains to the individual's reported or observed levels of anxiety or emotional distress, which may be indicative of underlying psychological issues.",
  PatternsInBehavior:
    "Patterns or Changes in Behavior: Refers to any recurring actions or noticeable changes in the person's behavior, which can offer insights into their mental and emotional health.",
  RedFlagsOrEvents:
    "Red Flags or Critical Events: Denotes any alarming signs or critical incidents reported or observed during the session, which might necessitate immediate attention or action.",
  SignificantQuotes:
    "Significant Quotes From the User: Represents notable statements made by the individual during the session, which may encapsulate their feelings, beliefs, or attitudes.",
  SourcesOfStress:
    "Specific Sources of Stress: Refers to any specific events, situations, or factors that the individual identifies as stress-inducing or anxiety-provoking.",
  DiscussionTopics:
    "Summary of Discussion Topics: Provides a brief overview of the main topics that were discussed during the session, helping to track the progression of therapy.",
  FutureSessionsSuggestions:
    "Suggestions for Future Sessions: These are recommendations or proposed actions for upcoming sessions, aimed at addressing the individual's needs or goals.",
  SuicideScore:
    "Suicide Score (Range from 0-5): This is a measure of the individual's suicide risk, based on their self-reported thoughts, feelings, and behaviors, with 0 indicating no risk and 5 indicating severe risk.",
  DefenseMechanisms:
    "Use of Defense Mechanisms: Refers to the individual's use of psychological strategies to cope with reality and maintain their self-image, which can provide insights into their emotional and mental health.",
};

function Modal(props) {
  const [choiceSelection, setChoiceSelection] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    clearSelection();
    props.onClose();
  };

  const setChoices = (selections) => {
    setErrorMessage("");
    setChoiceSelection(selections);
  };

  const onSelectAll = () => {
    const selections = {};
    Object.keys(therapy_info_data).forEach((choice) => {
      selections[choice] = true;
    });
    setChoices(selections);
  };

  const clearSelection = () => {
    setChoices({});
  };

  useEffect(() => {
    ReactModal.setAppElement("#modal");
  }, []);

  const onSubmit = () => {
    const selectedChoicesArray = Object.keys(choiceSelection).filter((choice) => choiceSelection[choice]);
    if (selectedChoicesArray.length > 0) {
      props.onSubmit(selectedChoicesArray);
      clearSelection();
    } else setErrorMessage("Please select atleast one choice!");
  };

  const onChoiceSelectionChange = (choiceKey) => {
    choiceSelection[choiceKey] = !choiceSelection[choiceKey];
    setChoices({ ...choiceSelection });
  };
  return (
    <ReactModal
      isOpen={props.isModalOpen}
      style={customStyles}
      onRequestClose={closeModal}
      contentLabel="Select the options"
    >
      <div className="modal-content">
        <h4>Select topics to be printed in the PDF:</h4>
        {Object.keys(therapy_info_data).map((choiceKey) => {
          const choiceText = therapy_info_data[choiceKey];
          const [option, summary] = choiceText.split(":");
          return (
            <React.Fragment key={choiceKey}>
              <div
                className={choiceSelection[choiceKey] ? "choice-label-selected" : "choice-label"}
                onClick={() => {
                  onChoiceSelectionChange(choiceKey);
                }}
              >
                {option}
              </div>
            </React.Fragment>
          );
        })}
        {/* <div className="modal-buttons"> */}
        <button id="select-all" onClick={onSelectAll}>
          Select All
        </button>
        <button id="clear-selection" onClick={clearSelection}>
          Clear Selection
        </button>

        <button
          id="close-select-all"
          onClick={() => {
            closeModal();
          }}
        >
          Close
        </button>

        <button id="modalSubmit" onClick={onSubmit}>
          Submit
        </button>
        <div style={{ color: "red" }}>{errorMessage}</div>
        {/* </div> */}
      </div>
    </ReactModal>
  );
}

export default Modal;
