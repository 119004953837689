import { useState, useEffect, useRef } from "react";
import axios from "axios";

const openAIKey = process.env.REACT_APP_OPENAI_KEY;

const VoiceInterface = () => {
  const mediaRecorderRef = useRef(null); // The microphone
  const audioChunksRef = useRef([]); // Used to store the user audio as its being recorded
  const [audioObject, setAudioObject] = useState(null); // Stores an audio object for handling audio playback
  const [microphonePermissionStatus, setMicrophonePermissionStatus] = useState("unknown");

  /**
   * Verifies that access has been granted to use the microphone.
   * @returns true if access has been granted, false otherwise.
   */
  const checkMicrophoneAccess = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setMicrophonePermissionStatus("granted"); // Permission granted
      return true;
    } catch (error) {
      console.error("Error accessing audio device:", error);
      if (error.name === "NotAllowedError") {
        setMicrophonePermissionStatus("denied"); // Permission denied
      } else {
        setMicrophonePermissionStatus("error"); // Other errors
      }
      return false;
    }
  };

  /**
   * Starts recording audio if mic access is granted.
   * Writes audio data to audioChunksRef.
   * @returns nothing.
   */
  const startRecording = () => {
    if (microphonePermissionStatus === "granted") {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.start();

          mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };
        })
        .catch((err) => console.error("Error accessing audio device:", err));
    } else if (microphonePermissionStatus === "unknown" || microphonePermissionStatus === "denied") {
      checkMicrophoneAccess(); // Attempt to request access if unknown or previously denied
    } else {
      alert("Microphone access is required to start recording.");
    }
  };

  /**
   * Stops recording user audio and creates an audio object (mp3).
   * @returns the audio object which can be passed to speechToText().
   */
  const stopRecording = () => {
    return new Promise((resolve, reject) => {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
        mediaRecorderRef.current.onstop = async () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: "audio/mp3" });
          audioChunksRef.current = [];
          try {
            resolve(audioBlob);
          } catch (error) {
            reject(error);
          }
        };
      }
    });
  };

  /**
   * Calls OpenAI transcription api to get text transcription from audio.
   * @param audioBlob Audio to be converted to text
   * @returns the text transcription of audio.
   */
  const speechToText = async (audioBlob) => {
    try {
      const formData = new FormData();
      formData.append("file", audioBlob);
      formData.append("model", "whisper-1");
      formData.append("response_format", "text");

      const transcription = await axios.post("https://api.openai.com/v1/audio/transcriptions", formData, {
        headers: {
          Authorization: `Bearer ${openAIKey}`,
          "Content-Type": "multipart/form-data",
        },
      });

      return transcription.data;
    } catch (error) {
      console.error("Error transcribing audio to text: ", error);
    }
  };

  /**
   * Calls OpenAI text to speech api to get audio from text.
   * Available voices are "alloy", "echo", "fable", "onyx", "nova", "shimmer"
   * @param text Text to be converted to audio
   * @param voice The OpenAI voice model to use (string). See https://platform.openai.com/docs/guides/text-to-speech
   * @returns the url to the audio, and plays the audio generated from 'text'.
   */
  const textToSpeech = async (text, voice) => {
    const chatbotVoice = voice ? voice : "echo";
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/audio/speech",
        {
          model: "tts-1",
          voice: chatbotVoice,
          input: text,
        },
        {
          headers: {
            Authorization: `Bearer ${openAIKey}`,
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );

      // The API response includes an array buffer of the audio file
      const audioURL = URL.createObjectURL(new Blob([response.data]));
      const audio = new Audio(audioURL);
      setAudioObject(audio); // Set the audio object in case the user wants to stop the audio playback
      audio.play();
      return audioURL;
    } catch (error) {
      console.error("Error generating speech:", error);
    }
  };

  /**
   * Stops / interrupts audio playback, and resets the audio object.
   * @returns nothing
   */
  const stopAudioPlayback = () => {
    if (audioObject) {
      audioObject.pause(); // Pause the audio playback
      setAudioObject(null); // Clear the audio object from the state
    }
  };

  return {
    checkMicrophoneAccess,
    startRecording,
    stopRecording,
    speechToText,
    textToSpeech,
    stopAudioPlayback,
  };
};

export default VoiceInterface;
