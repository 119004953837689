import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import "../App.css";
import Footer from "./Footer";

const TermsAndConditions = () => {
  return (
    <section className="t-and-c-container">
      <Header />
      <div className="t-and-c-content">
        <h1>Terms and Conditions for Blue Guardian</h1>
        <div className="text-block">
          <p>
            Welcome to Blue Guardian, a mental health app designed to help you manage your mental health and well-being.
            The following terms and conditions (“Terms”) apply to your use of Blue Guardian. By using our app, you agree
            to these Terms. Please read them carefully.
          </p>
        </div>
        <div className="text-block">
          <h4>Acceptance of Terms</h4>
          <p>
            By using the Blue Guardian app, you agree to these Terms and our{" "}
            <Link className="text-block-link" to="/privacy-policy">
              Privacy Policy
            </Link>
            . If you do not agree with these terms, please do not use the app.
          </p>
        </div>
        <div className="text-block">
          <h4>Services</h4>
          <p>
            Blue Guardian provides mental health resources and support to help manage and improve your mental health and
            well-being. We offer a range of services including assessments, self-help tools, and access to licensed
            mental health professionals.
          </p>
        </div>
        <div className="text-block">
          <h4>User Accounts</h4>
          <p>
            To use our app, you must create a user account. You are responsible for keeping your account information
            confidential and up to date. You may not share your account information with anyone else.
          </p>
        </div>
        <div className="text-block">
          <h4>Eligibility</h4>
          <p>
            You must be 18 years or older to use Blue Guardian. If you are under the age of 18, you must have the
            consent of a parent or legal guardian to use the app.
          </p>
        </div>
        <div className="text-block">
          <h4>Content</h4>
          <p>
            Blue Guardian provides information and resources related to mental health. This content is not intended to
            be a substitute for professional medical advice, diagnosis, or treatment. Always seek the advice of a
            qualified mental health professional with any questions you may have regarding a medical condition.
          </p>
        </div>
        <div className="text-block">
          <h4>Use of Information</h4>
          <p>
            By using the Blue Guardian app, you agree that we may collect, use, and share your personal information as
            outlined in our Privacy Policy.
          </p>
        </div>
        <div className="text-block">
          <h4>Prohibited Activities</h4>
          <p>
            You may not use Blue Guardian for any illegal or unauthorized purpose. You may not interfere with the
            security or integrity of the app or attempt to gain unauthorized access to any part of the app.
          </p>
        </div>
        <div className="text-block">
          <h4>User Conduct</h4>
          <p>
            You agree to use Blue Guardian responsibly and respectfully. You may not use the app to harass, intimidate,
            or bully any other users.
          </p>
        </div>
        <div className="text-block">
          <h4>Third-Party Links</h4>
          <p>
            Blue Guardian may contain links to third-party websites or services. We are not responsible for the content
            or privacy practices of these third-party sites.
          </p>
        </div>
        <div className="text-block">
          <h4>Intellectual Property</h4>
          <p>
            The Blue Guardian app and all of its content are the property of Blue Guardian and are protected by
            intellectual property laws. You may not reproduce, modify, or distribute any part of the app without our
            written permission.
          </p>
        </div>
        <div className="text-block">
          <h4>Disclaimer of Warranties</h4>
          <p>
            Blue Guardian provides the app “as is” and makes no warranties, express or implied, regarding the app or its
            content.
          </p>
        </div>
        <div className="text-block">
          <h4>Limitation of Liability</h4>
          <p>
            Blue Guardian is not liable for any damages arising from the use of the app or its content, including but
            not limited to, direct, indirect, incidental, punitive, and consequential damages.
          </p>
        </div>
        <div className="text-block">
          <h4>Indemnification</h4>
          <p>
            You agree to indemnify and hold Blue Guardian harmless from any claims, losses, or damages arising from
            using the app or violating these Terms.
          </p>
        </div>
        <div className="text-block">
          <h4>Termination</h4>
          <p>
            Blue Guardian may terminate your account or access to the app at any time for any reason without notice.
          </p>
        </div>
        <div className="text-block">
          <h4>Governing Law</h4>
          <p>
            These Terms are governed by and construed in accordance with the laws of the jurisdiction in which Blue
            Guardian operates.
          </p>
        </div>
        <div className="text-block">
          <h4>Modifications to Terms</h4>
          <p>
            Blue Guardian may modify these Terms at any time without notice. Your continued use of the app after any
            such modifications will constitute your acceptance of the modified Terms.
          </p>
        </div>
        <div className="text-block">
          <h4>Entire Agreement</h4>
          <p>
            These Terms constitute the entire agreement between you and Blue Guardian regarding the use of the app and
            supersede all prior agreements and understandings, whether written or oral.
          </p>
        </div>
        <div className="text-block">
          <p>Thank you for using Blue Guardian!</p>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default TermsAndConditions;
