// Footer.js

import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../images/logo.png";

// TODO: Add/update links to corresponding pages or page sections
const Footer = ({ scrollToSection }) => {
  return (
    <footer>
      <div className="footer-content-container">
        <div className="footer-main-content">
          <div className="footer-logo-container">
            <img src={logoImage} alt="Logo" />
            <h3>BLUE GUARDIAN</h3>
          </div>
          <div className="footer-links-container">
            {/* prettier-ignore */}
            <div className="footer-link-container">
              <div className="footer-link-header"><p>About Us</p></div>
              <Link className="footer-link" to="" onClick={() => scrollToSection("section4")}><p>Company</p></Link>
              <Link className="footer-link" to="/"><p>Product Demo</p><span>New ✨</span></Link>
              <Link className="footer-link" to="/"><p>Press</p></Link>
            </div>
            {/* prettier-ignore */}
            <div className="footer-link-container">
              <div className="footer-link-header"><p>Subscription</p></div>
              <Link className="footer-link" to="" onClick={() => scrollToSection("section3")}><p>Pricing</p></Link>
              <Link className="footer-link" to="" onClick={() => scrollToSection("section3")}><p>Premium 🔥</p></Link>
            </div>
            {/* prettier-ignore */}
            <div className="footer-link-container">
              <div className="footer-link-header"><p>Resources</p></div>
              <Link className="footer-link" to="/blog"><p>Blog</p></Link>
              <Link className="footer-link" to="/"><p>Contact Us</p></Link>
              <Link className="footer-link" to="" onClick={() => scrollToSection("section1")}><p>FAQs</p></Link>
            </div>
            {/* prettier-ignore */}
            <div className="footer-link-container">
              <div className="footer-link-header"><p>Site</p></div>
              <Link className="footer-link" to="/privacy-policy"><p>Privacy Policy</p></Link>
              <Link className="footer-link" to="/terms-and-conditions"><p>Terms & Conditions</p></Link>
              <Link className="footer-link" to="/"><p>Cookies</p></Link>
              <Link className="footer-link" to="/"><p>Accessibility</p></Link>
            </div>
          </div>
        </div>
        <div className="copyright-text">
          <p>&copy; 2024, Blue Guardian Inc. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
