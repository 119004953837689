import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { FaChevronLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import SignUpCTASection from "../components/SignUpCTASection";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import TopButton from "../components/TopButton";
// prettier-ignore
import {
  FacebookShareButton, FacebookIcon, 
  LinkedinShareButton, LinkedinIcon,
  EmailShareButton, EmailIcon,
  PinterestShareButton, PinterestIcon,
  RedditShareButton, RedditIcon,
  TwitterShareButton, XIcon,
  WhatsappShareButton, WhatsappIcon, 
} from "react-share"

const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_KEY;
const CONTENTFUL_GRAPHQL_BASE_URL = process.env.REACT_APP_CONTENTFUL_GRAPHQL_BASE_URL;

const BlogTemplate = () => {
  const { slug } = useParams();
  const [blogPost, setBlogPost] = useState(null);
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Defines a GraphQL query to fetch blog data upon component mount from Contentful,
  // executes the query, then sets the current blog post and an array of up to 10
  // most recent other blog posts.
  useEffect(() => {
    // blogPostCollection queries data for the (up to) 10 other blogs
    // blogPost: blogPostCollection queries data for the current blog
    const query = `
    query GetBlogData($slug: String!) {
      blogPostCollection(where: { showOnWebsite: true }, limit: 10, order: date_DESC) {
        items {
          slug
          title
          subtitle
          date
          photo {
            url
          }
          author {
            name
            photo {
              url
            }
          }
        }
      }
      blogPost: blogPostCollection(where: { slug: $slug }) {
        items {
          title
          subtitle
          date
          photo {
            url
          }
          author {
            name
          }
          article {
            json
          }
        }
      }
    }
    `;

    const variables = { slug };

    // Executing the query
    fetch(`${CONTENTFUL_GRAPHQL_BASE_URL}${CONTENTFUL_SPACE_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ query, variables }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.blogPostCollection.items.length > 0) {
          // Set the list of blog posts that will appear in the carousel at the bottom of the page
          setBlogPosts(data.data.blogPostCollection.items);
        }
        if (data.data.blogPost.items.length > 0) {
          // Set the individual blog post that matches the slug
          setBlogPost(data.data.blogPost.items[0]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
        setLoading(false);
      });
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // The blogs that appear in the carousel at the bottom of a blog page
  const carouselItems = blogPosts
    .filter((blog) => blog.slug !== slug)
    .map((blog, index) => (
      <div
        key={index}
        className="blog-card"
        onClick={() => {
          navigate(`/blog/${blog.slug}`);
          window.scrollTo(0, 0);
        }}
      >
        <div className="blog-card-content">
          <img src={blog.photo.url} alt={blog.title} loading="lazy" />
          <div className="blog-card-text-content">
            <h2 className="blog-card-title">{blog.title}</h2>
            <div className="blog-card-author-container">
              <img src={blog.author.photo.url} alt={blog.author.name} loading="lazy" />
              <p className="blog-card-author-name">{blog.author.name}</p>
              <p className="blog-card-dot">•</p>
              <p className="blog-card-date">{formatDate(blog.date)}</p>
            </div>
            <p className="blog-card-subtitle">{blog.subtitle}</p>
          </div>
        </div>
      </div>
    ));

  // Default data for the OG (Open Graph) and twitter metadata head tags
  const defaultMetadataImgURL = `https://www.blueguardian.ca/BGlogo.png`;
  const metadata = {
    pageTitle: blogPost ? `${blogPost.title} | Blog | Blue Guardian` : "Blog | Blue Guardian",
    blogTitle: blogPost ? blogPost.title : "Blog | Blue Guardian",
    subtitle:
      blogPost && blogPost.subtitle
        ? blogPost.subtitle
        : "Blue Guardian is an AI mental health startup focused on providing tailored mental health services through an AI virtual therapist.",
    photo: blogPost && blogPost.photo ? blogPost.photo.url : defaultMetadataImgURL,
  };
  // URL that gets shared with the blog
  const shareURL = `https://www.blueguardian.ca/blog/${slug}`;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>An error occurred: {error.message}</p>;
  if (!blogPost) return <p>Post not found.</p>;

  return (
    <div className="blog-template-page">
      <Helmet>
        <title>{metadata.pageTitle}</title>
        <meta property="og:title" content={metadata.blogTitle} />
        <meta property="og:description" content={metadata.subtitle} />
        <meta property="og:image" content={metadata.photo} />
        <meta property="og:image" content={defaultMetadataImgURL} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metadata.blogTitle} />
        <meta name="twitter:description" content={metadata.subtitle} />
        <meta name="twitter:image" content={metadata.photo} />
        <meta name="twitter:image:alt" content={metadata.blogTitle} />
      </Helmet>
      <Header />
      <div className="blog-template-hero-section">
        {/* Photo */}
        <img src={blogPost.photo.url} alt={blogPost.title} loading="lazy" />
        <div className="blog-template-hero-section-content">
          {/* Button back to blog list */}
          <div className="blog-template-back-container" onClick={() => navigate("/blog")}>
            <FaChevronLeft className="blog-template-back-icon" />
            <p>Back to Blogs</p>
          </div>
          <div className="blog-template-hero-section-text-content">
            {/* Title & subtitle */}
            <div className="blog-template-title">{blogPost.title}</div>
            <div className="blog-template-subtitle">{blogPost.subtitle}</div>
            {/* Author Name / Date */}
            <div className="blog-template-author-date">
              <p className="blog-template-author">{blogPost.author.name}</p>
              <p className="blog-template-dot">•</p>
              <p className="blog-template-date">{formatDate(blogPost.date)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-template-content-container">
        <article className="blog-template-article">
          {/* Add this blog's categories here in the future */}
          <div>{documentToReactComponents(blogPost.article.json)}</div>
        </article>
        <div className="blog-template-sidebar">
          <div className="blog-template-social-media-container">
            <h4>Share This Blog</h4>
            <div className="blog-template-social-media-icons-container">
              <FacebookShareButton url={shareURL}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <TwitterShareButton url={shareURL} title={metadata.blogTitle}>
                <XIcon size={32} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton
                url={shareURL}
                title={metadata.blogTitle}
                summary={metadata.subtitle}
                source={"https://www.blueguardian.ca/"}
              >
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              <WhatsappShareButton url={shareURL} title={metadata.blogTitle}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
              <RedditShareButton url={shareURL} title={metadata.blogTitle}>
                <RedditIcon size={32} round={true} />
              </RedditShareButton>
              <PinterestShareButton
                url={shareURL}
                media={metadata.photo}
                description={`${metadata.blogTitle} - ${metadata.subtitle} `}
              >
                <PinterestIcon size={32} round={true} />
              </PinterestShareButton>
              <EmailShareButton
                url={shareURL}
                subject={`Check out this blog from Blue Guardian - ${metadata.blogTitle}`}
                body={`${blogPost.subtitle}`}
              >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>
            </div>
          </div>
        </div>
      </div>
      <div className="other-blogs-container">
        {/* Carousel with other blogs */}
        <h2 className="other-blogs-heading">Other Blogs You'll Love</h2>
        <Carousel items={carouselItems} componentID="blog-template" scrollSnapType="start" />
      </div>
      <SignUpCTASection />
      <Footer />
      <TopButton />
    </div>
  );
};

export default BlogTemplate;
