import React, { useState } from "react";
import { Link } from "react-router-dom";
import firebaseConfig from "../backend/firebaseConfig";
import * as firebaseAuth from "firebase/auth";
import { initializeApp } from "firebase/app";
import Company from "./Company";
import "./LoginPage.css";

const app = initializeApp(firebaseConfig);
const auth = firebaseAuth.getAuth(app);

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [passwordWasReset, setPasswordWasReset] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await firebaseAuth.sendPasswordResetEmail(auth, email);
      setPasswordWasReset(true);
      setPasswordResetError(false);
    } catch (error) {
      setPasswordWasReset(false);
      setPasswordResetError(true);
      console.error("Reset password error:", error);
    }
  };

  return (
    <section className="authorization-page-container">
      <div className="authorization-page-content">
        <div className="auth-page-company-logo">
          <Company />
        </div>
        <div className="auth-page-form-content">
          <h1>Reset Password</h1>
          <form onSubmit={handleResetPassword}>
            <div className="input-container">
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder=" "
                required
              />
              <label className="input-label" htmlFor="email">
                Email address
              </label>
            </div>
            <button type="submit" className="action-button">
              Reset Password
            </button>
          </form>
          <div className="auth-cta-text-container">
            <Link to="/login">Back to login</Link>
          </div>
          {passwordWasReset && (
            <p className="password-reset-message">Please check your inbox with instructions to reset your password.</p>
          )}
          {passwordResetError && <p className="password-reset-message">There was an issue resetting your password.</p>}
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
