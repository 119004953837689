import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FaChevronLeft, FaUserAlt } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import { initializeApp } from "firebase/app";
import ChatApi from "../Services/ChatApi";
import AuthService from "../Services/AuthService";
import ChatGoalCards from "./ChatGoalCards";
import logoImg from "../images/logo.png";
import PricingPlan from "./PricingPlan";
import Chatbot from "../backend/Chatbot";
import SettingsMenu from "./SettingsMenu";
import Popup from "./Popup";
import firebaseConfig from "../backend/firebaseConfig";
import * as firebaseAuth from "firebase/auth";
import "./Dashboard.css";

const app = initializeApp(firebaseConfig);
const auth = firebaseAuth.getAuth(app);

const Dashboard = ({ profile, logOut }) => {
  const [isNightMode, setNightMode] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const location = useLocation();
  const chatbotRef = useRef();
  const [chatSessions, setChatSessions] = useState([]); // TODO: Doesn't do anything, remove
  const [chatMessages, setChatMessages] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [selectedChatMessages, setSelectedChatMessages] = useState([]);
  const [selectedChatMessagesRefresh, setSelectedChatMessagesRefresh] = useState([]);
  const [isCreatingNewChat, setIsCreatingNewChat] = useState(false);
  const [forceRerender, setForceRerender] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [showDeleteChatConfirmation, setShowDeleteChatConfirmation] = useState(false);
  const { createNewChat, getAllChats, getMessagesFromChat, deleteChat } = ChatApi();
  const { isLoggedIn, getUserDetails, getStripeSubscriptionStatus, changeSignupPref } = AuthService();
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [allChats, setAllChats] = useState([]);
  const [timestamp, setTimestamp] = useState(null);
  const [userDetails, setUserDetails] = useState({
    uid: "",
    email: "",
    firstName: "",
    lastName: "",
    provider: "",
  });
  const [userPhoto, setUserPhoto] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const [showChatGoalCards, setShowChatGoalCards] = useState(false);
  const [currentPlanStatus, setCurrentPlanStatus] = useState(null);
  const [showPricingPopup, setShowPricingPopup] = useState(false);
  const [showSubscriptionConfirmationPopup, setShowSubscriptionConfirmationPopup] = useState(false);
  const [manageSubscriptionButtonEnabled, setManageSubscriptionButtonEnabled] = useState(false);
  const apiResponsePayment = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [seed, setSeed] = useState(1); // Quick fix

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setNightMode(theme === "dark" ? true : false);
    }
  }, []);

  useEffect(() => {
    const checkLoggedIn = async () => {
      const loggedIn = await isLoggedIn();
      if (loggedIn.status === false) {
        navigate("/login", { replace: true });
      } else {
        navigate("/dashboard", { replace: true });
      }
    };
    checkLoggedIn();
  }, [navigate]);

  useEffect(() => {
    // Fetch user details when the component mounts
    const UserDetails = async () => {
      try {
        const data = await getUserDetails();
        setUserDetails((prevDetails) => ({
          ...prevDetails,
          uid: data.uid,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          provider: data.provider,
        }));
      } catch (error) {
        console.error("Error fetching user details", error);
      }
    };

    UserDetails();
  }, []);

  useEffect(() => {
    setUserPhoto(profile ? profile.providerData[0].photoURL : "");
  }, []);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const chats = await getAllChats();
        setAllChats(chats);
        setTimestamp(timestamp);
      } catch (error) {
        console.error("Error fetching chats:", error.message);
      }
    };

    fetchChats();
  }, [forceRerender]);

  /**
   * Updates the chat session with id=chatID to newTitle.
   */
  const updateChatTitle = (chatID, newTitle) => {
    setAllChats((prevAllChats) => {
      const updatedChats = prevAllChats.map((chat) => {
        if (chat.id === chatID) {
          return { ...chat, title: newTitle };
        }
        return chat;
      });
      return updatedChats;
    });
  };

  const handleSettingsClick = () => {
    setShowSettings(!showSettings);
  };

  const handlePayments = () => {
    setShowPricingPopup(true);
  };

  const handlePlanSubscription = (planType) => {
    setCurrentPlanStatus(planType);
    const payload = planType === "Premium" ? true : false;
    dispatch({ type: "SET_SUBSCRIPTION_STATUS", payload: payload });
    // Wait for the currentPlanStatus to update before showing the popup
    setShowPricingPopup(false);
    setShowSubscriptionConfirmationPopup(true);
  };

  useEffect(() => {
    const currentURL = window.location.href;
    if (currentURL.endsWith("/dashboard?")) {
      setShowSubscriptionConfirmationPopup(true);
    }
  }, []);

  const handleCreateNewChatSession = async (data) => {
    try {
      const chatGoal = data.chatGoal;
      const chatStyle = data.chatStyle;
      const { chatId } = await createNewChat({ chatGoal, chatStyle });
      const chats = await getAllChats();
      setChatGoalAndStyle({ chatGoal: null, chatStyle: null }); // Reset chat goal and style

      // Find the newly created chat in the API response
      const newChat = chats.find((chat) => chat.id === chatId);

      setCurrentChatId(chatId);
      setChatSessions(chats);
      setTimestamp(chats.startTime);
      setTimestamp(timestamp);
      setSelectedSessionId(chatId);
      localStorage.setItem("selectedSessionId", chatId);

      setAllChats((prevChats) => [...prevChats, { id: chatId, title: newChat.title, startTime: newChat.startTime }]);

      setCurrentSession({
        chatId: newChat.chatId,
      });
      setIsCreatingNewChat(true);
      chatbotRef.current.resetChatbotState();
      return chatId;
    } catch (error) {
      console.error("Error creating new chat:", error.message);
    }
  };

  const handleSessionClick = async (chatId) => {
    setSeed(Math.random()); // Quickfix
    setCurrentChatId(chatId); // Quickfix
    try {
      setSelectedSessionId(chatId);
      const messages = await getMessagesFromChat(chatId);
      setChatMessages((prevChatMessages) => {
        return {
          ...prevChatMessages,
          [chatId]: messages,
        };
      });

      setCurrentChatId(chatId);
      localStorage.setItem("selectedSessionId", chatId);
      const currSessionClick = localStorage.getItem("selectedSessionId", chatId);
    } catch (error) {
      console.error(`Error getting messages for chat ${chatId}:`, error.message);
    }
  };

  const fetchAndSetMessages = async (chatId) => {
    try {
      const messages = await getMessagesFromChat(chatId);
      setChatMessages((prevChatMessages) => ({
        ...prevChatMessages,
        [chatId]: messages,
      }));

      setSelectedChatMessages(messages);
      setCurrentChatId(chatId);
    } catch (error) {
      console.error(`Error getting messages for chat ${chatId}:`, error.message);
    }
  };

  useEffect(() => {
    const storedSessionId = localStorage.getItem("selectedSessionId");
    if (storedSessionId) {
      setCurrentChatId(storedSessionId);
      setSelectedSessionId(storedSessionId);
      fetchAndSetMessages(storedSessionId);
    }
  }, []);

  useEffect(() => {
    setSelectedChatMessages(chatMessages[currentChatId] || []);
  }, [chatMessages, currentChatId]);

  const handleDeleteChatClick = async (chatId) => {
    setSelectedChatId(chatId);
    setShowDeleteChatConfirmation(true);
  };

  const handleYesDeleteChatConfirmation = async (chatId) => {
    try {
      await deleteChat(chatId);

      const storedSessionId = localStorage.getItem("selectedSessionId");
      if (storedSessionId === chatId) {
        localStorage.removeItem("selectedSessionId");
      }

      setAllChats((prevChats) => {
        const updatedChats = prevChats.filter((chat) => chat.chatId !== chatId);
        setForceRerender((prev) => !prev);
        return updatedChats;
      });

      if (currentChatId === chatId) {
        // Quickfix
        setSelectedChatMessages([]);
        setChatMessages([]);
        // Remove the current Chat Id
        setCurrentChatId(null);
        setSeed(Math.random()); // Quickfix - refreshes chat messages
      }
    } catch (error) {
      console.error(`Error deleting chat ${chatId}:`, error.message);
    }

    setShowDeleteChatConfirmation(false);
  };

  const deleteAllChats = async () => {
    try {
      if (allChats.length === 0) {
        return { success: false, message: "You have no chats to delete." };
      }
      // Iterate through allChats and delete each chat
      for (let i = 0; i < allChats.length; i++) {
        handleYesDeleteChatConfirmation(allChats[i].id);
      }
      return { success: true, message: "Your chats have successfully been deleted." };
    } catch (e) {
      throw e;
    }
  };

  const [menuOpen, setMenuOpen] = useState(window.innerWidth > 1024); // Menu open by default on screens > 1024px, closed by default on screens < 1024px
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // Handles menu opening logic for screen resizing
  const handleResize = () => {
    if (window.innerWidth > 1024 && !menuOpen) {
      // If the window is resized to a larger size and the menu is closed, keep it closed
      setMenuOpen(true);
      return;
    }

    if (window.innerWidth > 1024 && menuOpen) {
      // If the window is resized to a larger size and the menu is open, keep it open
      setMenuOpen(true);
      return;
    }

    if (window.innerWidth <= 1024 && menuOpen) {
      // If the window is resized to a smaller size and the menu is open, keep it open
      setMenuOpen(false);
      return;
    }

    if (window.innerWidth <= 1024 && !menuOpen) {
      // If the window is resized to a smaller size and the menu is closed, keep it closed
      setMenuOpen(false);
      return;
    }
  };

  // Helper for menu opening logic on screen resizing
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowChatGoalCards = () => {
    setShowChatGoalCards(!showChatGoalCards);
  };

  const [menuNewChatClicked, setMenuNewChatClicked] = useState(null);
  const handleNewChatClickFromMenu = () => {
    setMenuNewChatClicked(true);
    setShowChatGoalCards(!showChatGoalCards);
  };

  const handleNewSubscriptionCreateChat = () => {
    setShowSubscriptionConfirmationPopup(false);
    setShowPricingPopup(false);
    setMenuNewChatClicked(true);
    setShowChatGoalCards(true);
  };

  const handleShowPricingPopup = () => {
    setShowPricingPopup(!showPricingPopup);
  };

  useEffect(() => {
    // Check if the user is navigating from login/signup to dashboard
    const isNavigatingFromAuth = location.state && location.state.fromAuth;

    if (isNavigatingFromAuth) {
      // Check if the user is navigating from the login or signup page
      const isNavigatingFromLogin = location.state && location.state.fromLogin;
      // const isNavigatingFromSignUp = location.state && location.state.fromSignUp;

      if (!isNavigatingFromLogin) {
        // Want them to subcribe to a plan after sign up
        setShowPricingPopup(true);
      }

      // Reset the navigation state to prevent showing the popup on page refresh
      navigate({ ...location, state: { fromAuth: false } });
    }
  }, [location, navigate]);

  /** Fetches the user's subscription status on component mount */
  useEffect(() => {
    const fetchStripeStatus = async () => {
      try {
        const response = await getStripeSubscriptionStatus();
        setCurrentPlanStatus(response.data.currentPlan);
        dispatch({ type: "SET_SUBSCRIPTION_STATUS", payload: response.data.status });
        if (response.data.status === true || response.data.status === false) {
          setManageSubscriptionButtonEnabled(true);
        } else {
          setManageSubscriptionButtonEnabled(false);
        }
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx
          console.error("Error fetching stripe status:", error.response.status, error.response.data);
          if (error.response.status === 400) {
            // User not subscribed to any plan
            console.error("Server error (400): ", error.response.data.message);
            // setCurrentPlanStatus(null);
            setShowPricingPopup(true);
          } else if (error.response.status === 500) {
            // Error fetching the stripe status (internal server error)
            console.error("Server error (500): ", error.response.data.message);
            // setCurrentPlanStatus(null); // TODO: handle differently
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("The request was made but no response was received", error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error", error.message);
        }
      }
    };
    fetchStripeStatus();
  }, []);

  const handleManageSubscriptionClick = () => {
    setShowSettings(false);
    setShowPricingPopup(true);
  };

  const handleBasicUserPremiumFeatureClick = () => {
    setShowPricingPopup(true);
  };

  const handleLinkGoogleClick = async () => {
    try {
      const provider = new firebaseAuth.GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: "select_account",
      });
      const currentUser = auth.currentUser;
      const userData = await firebaseAuth.linkWithPopup(currentUser, provider);
      // Check if the user's email matches their google email
      // Doing this because the user may have signed up with a different email,
      // and we want to make sure they are linking the google account to the correct email
      // In the future, may want to allow the user to link a google account to a non google email
      const linkedEmail = userData.user?.providerData[0].email;
      const signUpEmail = userDetails.email;
      if (linkedEmail !== signUpEmail) {
        // The user's google email does not match their sign up email,
        // so we need to unlink the google account
        const userDets = await firebaseAuth.unlink(currentUser, "google.com");
        return false;
      }

      const userInfo = {
        email: userData.user?.email,
        provider: "Google",
        googleId: userData.user?.providerData[0].uid,
      };
      const updatedUser = await changeSignupPref(userInfo);
      if (updatedUser.success) {
        const userDets = await firebaseAuth.unlink(currentUser, "password");

        setUserDetails((prevDetails) => ({ ...prevDetails, provider: "Google" }));
        setUserPhoto(userData.user?.providerData[0].photoURL);
      } else {
        const userDets = await firebaseAuth.unlink(currentUser, "google.com");
      }
      // Return result
      return updatedUser.success;
    } catch (error) {
      // Error handled in SettingsMenu.js handleLinkGoogleAccount function

      throw error;
    }
  };

  const [chatGoalAndStyle, setChatGoalAndStyle] = useState({ chatGoal: null, chatStyle: null });

  const handleSetChatGoalAndStyle = async (data) => {
    setChatGoalAndStyle(data);
    setShowChatGoalCards(false);
    if (!currentChatId && !menuNewChatClicked) {
      // User did not have a current chat session active,
      // and they did not click the menu "+ New Chat", so the chat goal and style being set
      // was triggered from them typing into the chat and hitting Submit while they did not have an active
      // chat session.
      // Need to pass the selected chat goal and chat style to chatbot to create a new chat session and then send what they typed
      setChatGoalAndStyle(data);
    } else if (menuNewChatClicked) {
      // If the user clicked the "+ New Chat" button from the menu just need to create the new chat session for them.
      setMenuNewChatClicked(false);
      handleCreateNewChatSession(data);
    }
    return data;
  };

  return (
    <div className={`dashboard ${isNightMode ? "night-mode" : ""}`}>
      <div
        className={`dashboard-left-navigator ${isNightMode ? "night-mode1" : ""} ${
          menuOpen ? "menu-open" : "menu-closed"
        } `}
      >
        <div className="dashboard-left-header">
          <div className="dashboard-left-header-content">
            <h2 className={`bg ${isNightMode ? "night-mode-leftheader" : ""}`}>BLUE GUARDIAN</h2>
            <div className="menu-current-plan">
              <div className="blue-line"></div>
              <h3 className={`bg ${isNightMode ? "night-mode-leftheader" : ""}`}>{currentPlanStatus}</h3>
              <div className="blue-line"></div>
            </div>
          </div>
        </div>

        <div className="dashboard-left-navigator-content">
          <button
            className={`addchat-button ${isNightMode ? "night-mode-button" : ""}`}
            onClick={handleNewChatClickFromMenu}
          >
            + New Session
          </button>

          <div className="chat-list">
            <div className={`sessions-header ${isNightMode ? "night-mode-session-header" : ""}`}>
              <p>Sessions</p>
            </div>
            <div className="chat-sessions-container">
              {[...allChats]
                .sort((a, b) => new Date(b.startTime) - new Date(a.startTime)) // Sort in descending order by startTime
                .map((chat, index) => (
                  <div
                    onClick={() => handleSessionClick(chat.id)}
                    className={`session-container ${isNightMode ? "night-mode-session" : ""} ${
                      selectedSessionId === chat.id ? "selected-session" : ""
                    }`}
                    key={index}
                  >
                    {/* <div className="chat-sessions"> */}
                    <div className="chat-session-info">
                      <p className={`chat-session-info-text ${isNightMode ? "night-mode-text" : ""}`}>
                        {chat.title ? chat.title : "New Session"}
                      </p>
                    </div>
                    {/* </div> */}
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the click from triggering handleSessionClick
                        handleDeleteChatClick(chat.id);
                      }}
                    >
                      <FaXmark
                        className={`${isNightMode ? "night-mode-icon" : ""}`}
                        style={{ width: "12px", height: "12px" }}
                      />
                    </button>
                  </div>
                ))}
            </div>
          </div>
          {/* {!apiResponsePayment && ( */}
          {(currentPlanStatus === null || currentPlanStatus === "Basic") && (
            <div className="chatbot-version-box" onClick={handlePayments}>
              <div className="chatbot-version-logo">
                <img src={logoImg} alt="Logo" />
              </div>
              <div className="chatbot-version-content">
                <p>Blue Guardian Premium</p>
                <p>Start Your Free Trial</p>
              </div>
            </div>
          )}

          <div className="small-box">
            <div className={`small-box-content ${isNightMode ? "night-mode-smallboxcontent" : ""}`}>
              <div className="small-box-user">
                {userPhoto ? <img src={userPhoto} alt="User" /> : <FaUserAlt className="user-img-alt-icon" />}
                <p>{profile?.displayName ? profile.displayName : userDetails.firstName + " " + userDetails.lastName}</p>
              </div>
              <div className="settings-icon-container" onClick={handleSettingsClick}>
                <FaGear className={`settings-icon ${isNightMode ? "night-mode-icon" : ""}`} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteChatConfirmation && (
        <Popup isNightMode={isNightMode} onClosePopup={() => setShowDeleteChatConfirmation(false)}>
          <div className="small-popup-container">
            <div className={`logout-confirmation ${isNightMode ? "night-mode-text" : ""}`}>
              <p>Are you sure you want to delete this chat?</p>
              <div className="popup-button-logout">
                <button
                  className="popup-button-logout1"
                  onClick={() => handleYesDeleteChatConfirmation(selectedChatId)}
                >
                  Yes
                </button>
                <button className="popup-button-logout1" onClick={() => setShowDeleteChatConfirmation(false)}>
                  No
                </button>
              </div>
            </div>
          </div>
        </Popup>
      )}

      {showSettings && (
        <Popup isNightMode={isNightMode} onClosePopup={handleSettingsClick}>
          <SettingsMenu
            isNightMode={isNightMode}
            onSetNightMode={setNightMode}
            currentPlanStatus={currentPlanStatus}
            userDetails={userDetails}
            onManageSubscriptionClick={handleManageSubscriptionClick}
            logOut={logOut}
            onDeleteAllChats={deleteAllChats}
            onLinkGoogleAccount={handleLinkGoogleClick}
          />
        </Popup>
      )}

      {showChatGoalCards && (
        <ChatGoalCards
          onGetChatGoalAndStyle={handleSetChatGoalAndStyle}
          // onCreateNewChat={handleSetChatGoalAndStyle}
          onCloseChatGoalCards={handleShowChatGoalCards}
          onUpgradeClick={handleShowPricingPopup}
          isNightMode={isNightMode}
          apiResponsePayment={apiResponsePayment}
        />
      )}

      {selectedChatMessages.map((message, index) => (
        <div key={index}>
          <p>{message.text}</p>
        </div>
      ))}

      {selectedChatMessagesRefresh.map((message, index) => (
        <div key={index}>
          <p>{message.text}</p>
        </div>
      ))}

      {showPricingPopup && (
        <div className="popup-container">
          <div className={`popup-content-wide ${isNightMode ? "popup-night-mode" : ""}`}>
            {currentPlanStatus && ( // Only show X if they aren't subbed to a plan (requires user sub to a plan)
              <span className="popup-settings-close" onClick={() => setShowPricingPopup(false)}>
                &times;
              </span>
            )}
            {/* Show welcome message if they aren't subbed to a plan */}
            {!currentPlanStatus && (
              <div className="welcome-message">
                <h3>Welcome to Blue Guardian!</h3>
                <p>Please select a plan to get started</p>
              </div>
            )}
            <PricingPlan
              stripeSubscriptionStatus={currentPlanStatus}
              onPlanSubscribe={handlePlanSubscription}
              isNightMode={isNightMode}
            />
          </div>
        </div>
      )}

      {showSubscriptionConfirmationPopup && (
        <Popup isNightMode={isNightMode} onClosePopup={() => setShowSubscriptionConfirmationPopup(false)}>
          <div className="small-popup-container">
            {currentPlanStatus ? (
              // Show this once currentPlanStatus is not undefined
              <div className="subscription-confirmation-popup-content">
                <p className="popup-text-basic">
                  <b>You Subscribed to the {currentPlanStatus} Plan! 🎉</b>
                </p>
                <button
                  className={`addchat-button ${isNightMode ? "night-mode-button" : ""}`}
                  onClick={handleNewSubscriptionCreateChat}
                >
                  Create a New Chat
                </button>
              </div>
            ) : (
              // Show a loading circle while waiting for currentPlanStatus to load
              // prettier-ignore
              <div className="subscription-confirmation-popup-content">
                <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
              </div>
            )}
          </div>
        </Popup>
      )}

      <div className={`dashboard-main ${isNightMode ? "night-mode" : ""} ${menuOpen ? "menu-open" : "menu-closed"}`}>
        <div className={`menu-toggle ${menuOpen ? "menu-open" : "menu-closed"}`} onClick={toggleMenu}>
          <FaChevronLeft className={`menu-toggle-icon ${menuOpen ? "menu-open" : "menu-closed"}`} />
        </div>

        <div className="dashboard-chatbot-inner">
          <Chatbot
            ref={chatbotRef}
            isNightMode={isNightMode}
            currentChatId={currentChatId}
            isCreatingNewChat={isCreatingNewChat}
            selectedChatMessages={chatMessages[currentChatId] || []}
            selectedChatMessagesRefresh={chatMessages[currentChatId] || []}
            allChats={allChats}
            setAllChats={setAllChats}
            updateChatTitle={updateChatTitle}
            setCurrentChatId={setCurrentChatId}
            currentSession={currentSession}
            setCurrentSession={setCurrentSession}
            setIsCreatingNewChat={setIsCreatingNewChat}
            setSelectedSessionId={setSelectedSessionId}
            stripeSubscriptionStatus={currentPlanStatus}
            onBasicUserPremiumFeatureClick={handleBasicUserPremiumFeatureClick}
            setShowChatGoalCards={setShowChatGoalCards}
            chatGoalAndStyle={chatGoalAndStyle}
            setChatGoalAndStyle={setChatGoalAndStyle}
            getChatGoalAndStyle={handleSetChatGoalAndStyle}
            submitButtonUsedForNewChatSession={!menuNewChatClicked}
            onCreateNewChatSession={handleCreateNewChatSession}
            key={seed}
          />
        </div>
        <footer>
          <div className={`footer-content ${isNightMode ? "night-mode" : ""}`}>
            <p>
              Free Research Preview. Blue Guardian may produce inaccurate information about people, places, or facts.
              <span>&nbsp;</span>&copy; 2024, Blue Guardian Inc. All Rights Reserved.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Dashboard;
