import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import SignUpCTASection from "./SignUpCTASection";
import Footer from "./Footer";
import TopButton from "./TopButton";

const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_CONTENT_DELIVERY_API_KEY;
const CONTENTFUL_GRAPHQL_BASE_URL = process.env.REACT_APP_CONTENTFUL_GRAPHQL_BASE_URL;

//TODO: Add pagination
const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]); // Array of all blog posts
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const query = `
      {
        blogPostCollection(where: {showOnWebsite: true}, order: date_DESC) {
          items {
            slug
            title
            subtitle
            date
            photo {
              url
            }
            author {
              name
              photo {
                url
              }
            }
            article {
              json
            }
          }
        }
      }
    `;

    fetch(`${CONTENTFUL_GRAPHQL_BASE_URL}${CONTENTFUL_SPACE_ID}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then((data) => {
        setBlogPosts(data.data.blogPostCollection.items);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
        setLoading(false);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>An error occurred: {error.message}</p>;

  return (
    <div className="blog-page-container">
      <Header />
      <div className="blog-preview-list-container">
        {blogPosts.map((blogPost) => (
          <div
            className="blog-preview-container"
            key={blogPost.slug}
            onClick={() => navigate(`/blog/${blogPost.slug}`)}
          >
            <div className="blog-preview-content">
              <div className="blog-preview-image">
                <img src={blogPost.photo.url} alt={blogPost.title} loading="lazy" />
              </div>
              <div className="blog-preview-text-content">
                <h2 className="blog-preview-title">{blogPost.title}</h2>
                <h4 className="blog-preview-subtitle">{blogPost.subtitle}</h4>
                <div className="blog-preview-author-container">
                  <img src={blogPost.author.photo.url} alt={blogPost.author.name} loading="lazy" />
                  <div className="blog-preview-author-date">
                    <p className="blog-preview-author-name">{blogPost.author.name}</p>
                    <p className="blog-preview-dot">•</p>
                    <p className="blog-preview-date">{formatDate(blogPost.date)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <SignUpCTASection />
      <Footer />
      <TopButton />
    </div>
  );
};

export default Blog;
