import React, { useState, useRef, useEffect } from "react";
import { FaArrowLeft, FaCircleArrowRight } from "react-icons/fa6";

/**
 * @param {JSX Array} items Array of JSX elements to be rendered in the carousel
 * @param {string} componentID Identifies the component that's using the carousel, so specific css classes can be
 *                    used on specific components (ie Testimonials, Team Cards, etc)
 * @param {string} scrollSnapType Behaviour of scroll snapping (OPTIONS: "start", "center", "end").
 * @returns Carousel rendering 'items' with clickable buttons to switch between the elements in 'items'.
 */
// FIXME: Logic for prevElement and nextElement; need to check if first or last element are in view to avoid clicks that don't change carousel
// FIXME: Update current index on scroll
const Carousel = ({ items, componentID, scrollSnapType }) => {
  const scrollSnapStyle = scrollSnapType || "start";
  const carouselContainerRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [firstItemInView, setFirstItemInView] = useState(true);
  // const [lastItemInView, setLastItemInView] = useState(false);

  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      scrollToCurrentIndex();
    }
  }, [currentIndex]);

  const prevElement = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const nextElement = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const scrollToCurrentIndex = () => {
    console.log("Scrolling to ", currentIndex);
    const container = carouselContainerRef.current;
    const currentElement = container.querySelector(`.carousel-item-${currentIndex}`);

    if (currentElement) {
      currentElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: scrollSnapStyle || "nearest",
      });
    }
  };

  return (
    <div id={componentID} ref={carouselContainerRef} className="carousel-container">
      <div className="carousel-buttons">
        <FaArrowLeft className="carousel-left-arrow" onClick={prevElement} />
        <FaCircleArrowRight className="carousel-right-arrow" onClick={nextElement} />
      </div>
      <div className="carousel-content">
        {items.map((item, index) => (
          <div
            key={index}
            id={componentID}
            className={`carousel-item carousel-item-${index} ${index === currentIndex ? "active" : "inactive"}`}
            style={{ scrollSnapAlign: scrollSnapStyle }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
