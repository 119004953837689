import React from "react";
import Company from "./Company";
import { Navigate, useNavigate } from "react-router-dom";
import "../App.css";

const EmailVerified = () => {
  const navigate = useNavigate();
  return (
    <div className="email-verified-page">
      <div className="email-verified-content">
        <div className="company-logo-container">
          <Company />
        </div>
        <p>
          <b>Your email has been verified!</b>
        </p>
        <button onClick={() => navigate("/login")}>Go to Login Page</button>
      </div>
    </div>
  );
};

export default EmailVerified;
